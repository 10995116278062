import { onMounted, ref } from "vue";

import aEntityAPI from "@/api/sysAPI.js";
import { renderHpHotVhcLine } from "@/assets/datav/js/hpVhcLine.js";

/**
 * @description: 可视化中国地图
 * @param {string} id 显示地图的DOM元素绑定的id
 */
export default function useChinaMap(id) {
  const performInterval = ref(60);
  const timeTicket = ref(null);

  const getDataInfo = () => {
    aEntityAPI.request("DatavService", "getHpVhcLineData").then((res) => {
      const mapData = res.data.data.mapData;
      renderHpHotVhcLine(document.getElementById(id), mapData);
    });
  };

  const buildChart = () => {
    getDataInfo();
    clearInterval(timeTicket.value);
    timeTicket.value = setInterval(function () {
      getDataInfo();
    }, performInterval.value * 1000);
  };

  onMounted(() => {
    buildChart();
  });
}
