<template>
  <div id="share" class="abput_us">
    <div class="pzh_com_title">
      <div class="pzh_com_title_cha">关于我们</div>
      <div class="pzh_com_title_eng">About us</div>
    </div>
    <div class="pzh_com_about">
      <img :src="imageUrl" class="pzh_com_about_right" />
      <div class="pzh_com_about_left">
        <div v-html="htmlContent" class="pzh_com_about_left_content">
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import aEntityAPI from "@/api/sysAPI.js";
export default {
  data() {
    return {
      companyDes: {},
      htmlContent:'',
      imageUrl:'',
      aboutUs: require("@/assets/luyi/about_us_pic1.png"),
    };
  },
  methods: {
    // getHomeDataInfo() {
    //   let that = this;
    //   aEntityAPI
    //     .request("HomePageConfigService", "getHomePageDataInfo")
    //     .then((res) => {
    //       if (res.data.success) {
    //         var dataDict = res.data.data;
    //         if (
    //           dataDict.companyDes != null &&
    //           dataDict.companyDes.contantDsc != null
    //         ) {
    //           dataDict.companyDes.contantDsc =
    //             dataDict.companyDes.contantDsc.replaceAll(
    //               "<p>",
    //               "<p style='color:#fff;font-size:calc(100vw / 1920 * 18) ; line-height:calc(100vh / 1080  * 47)'>"
    //             );

    //           that.companyDes = dataDict.companyDes;
    //         }
    //       }
    //     });
    // },
    getWebSiteAbout(){
      aEntityAPI
        .request("BroadCastService", "getWebSiteAbout")
        .then((res) => {
          if (res.data.success) {
            // this.sliders = res.data.data
            console.log(res.data.data,"sliders")
            this.imageUrl = res.data.data.imageUrl

            if (
              res.data.data != null &&
              res.data.data.htmlContent != null
            ) {
              res.data.data.htmlContent =
              res.data.data.htmlContent.replaceAll(
                  "<p>",
                  "<p style='color:#fff;font-size:18px;line-height:24px;'>"
                );

                this.htmlContent = res.data.data.htmlContent;
            }

          }
        });
    },
  },
  mounted() {
    this.getWebSiteAbout();
  },
};
</script>
<style>
/* 通用样式 */

.abput_us {
  background: #ffffff;
  width: 100%;
  height: 1200px;
}

.pzh_page_size {
  width: 100%;
  height: 100%;
}
.pzh_company {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
}
.pzh_company_top {
  width: 1200px;
  height: 355px;
  background-image: url("../assets/luyi/图层 18.png");
  background-size: 100% 100%;
}

.pzh_company_top_title {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pzh_com_title_line {
  width: 100%;
  height: 2px;
  background-color: white;
  margin: 20px 0px;
}
.pzh_com_title_cha {
  color: #333333 !important;
  margin: 0px 20px;
  font-size: 36px;
  font-weight: 500 !important;
}
.pzh_com_title_eng {
  color: #9b9b9b;
  margin-top: 5px;
  font-family: Source Han Sans SC;
  font-size: 20px;
}
.pzh_com_title {
  display: flex;
  padding: 80px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}

.pzh_com_about {
  width: 1200px;
  margin: 0 auto;
  text-align: right;
  /* position: relative; */
}

.pzh_com_about_left {
  margin-top: 54px;
  width: 617px;
  height: 516px;
  z-index: 999;
  background-image: url("../assets/luyi/about_us_pic2.png");
  background-size: 100% 100%;
  position: relative;
  top: -511px;
}
.pzh_com_about_left_content {
  padding: 34px 27px;
  text-align: left;
}
.pzh_com_about_right {
  width: 771px;
  height: 505px;
  /* position: relative;
  left: 428px;
  top: -80px; */
}
.pzh_company_body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 2;
  margin: 104px;
}
.pzh_company_body_contant {
  background-image: url("../assets/luyi/17.png");
  background-size: 50% 100%;
  background-position: calc(68vh) 0%;
  background-repeat: no-repeat;
  width: 771px;
  height: 504px;
  min-width: 1200px;
  min-height: 392px;
  width: calc(154vh);

  display: flex;
  flex-direction: row;
  align-items: center;
}
.pzh_company_body_desc {
  font-size: 1.5rem;
  padding: 2%;
  color: #fff;
  width: 617px;
  margin-left: 6%;
  height: 400px;
  background-image: url("../assets/luyi/WS2.png");
  background-size: 100% 100%;
  z-index: 20;
  text-align: justify;
}
</style>