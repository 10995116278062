<template>
  <div class="pzh_body_index">
    <el-carousel trigger="click" height="450px">
        <el-carousel-item v-for="slider in sliders" :key="slider.id">
          <div class="banner"  :style="'background-image: url(' + slider.imageUrl + ')'">
      
            <div class="banner_content">
              <div class="banner_left">
                <div class="banner_left_title1">唐山丰润区运输协会</div>
                <div class="banner_left_title2">专注互联网新物流</div>
              </div>
              <div v-if="!userInfo && typeIndex == 1" class="banner_right">
                
                <luyi-login></luyi-login>
              </div>
              <div v-if="!userInfo && typeIndex == 2" class="banner_right">
                
                <luyi-reg></luyi-reg>
              </div>
              <div v-if="!userInfo && typeIndex == 3" class="banner_right">
                
                <luyi-forget-pass></luyi-forget-pass>
              </div>
            
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
   

    <div style="background: #ffffff; width: 100%">
      <div class="platform">
        <div class="platform_left">
          <img :src="phonePng" class="platform_left_img" />
          <div class="platform_left_title">{{currentInfo ? currentInfo.title : ''}}</div>
          <div class="platform_left_time">{{currentInfo ? currentInfo.releaseTime : ''}}</div>
        </div>

        <div class="platform_right">
          <router-link
            :to="{ name: 'luyiNews' }"
            class="platform_right_more"
            style=""
            >+&nbsp;查看更多</router-link
          >
          <div style="display: flex; align-items: center">
            <div class="btn" @click="handleBefore">
              <img
                class="arrow_icon"
                :src="require('@/assets/luyi/left_arrow.png')"
              />
            </div>
            <div class="btn" @click="handleNext">
              <img
                class="arrow_icon"
                style="margin-left: 8px"
                :src="require('@/assets/luyi/right_arrow.png')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="commerce_data">
      <div class="pzh_com_title">
        <div class="pzh_com_title_cha">商会数据</div>
        <div class="pzh_com_title_eng">Chamber of commerce data</div>
      </div>
      <div class="pzh_com_content">
        <div class="pzh_com_icon">
          <div class="flex_column">
            <img :src="qysl" class="pzh_com_img" />
            <div style="font-size: 18px; color: #464646;margin-left: 12px;">运量累计</div>
          </div>
          <div class="pzh_com_pipeline">
            <div class="pzh_com_circle"></div>
            <div class="pzh_com_line"></div>
            <div class="pzh_com_circle"></div>
          </div>
          <div class="flex_column">
            <img :src="ydlj" class="pzh_com_img" />
            <div style="font-size: 18px; color: #464646;margin-left: 12px;">运单累计</div>
          </div>
          <div class="pzh_com_pipeline">
            <div class="pzh_com_circle"></div>
            <div class="pzh_com_line"></div>
            <div class="pzh_com_circle"></div>
          </div>
          <div class="flex_column">
            <img :src="sjsl" class="pzh_com_img" />
            <div style="font-size: 18px; color: #464646;margin-left: 12px;">司机数量</div>
          </div>
          <div class="pzh_com_pipeline">
            <div class="pzh_com_circle"></div>
            <div class="pzh_com_line"></div>
            <div class="pzh_com_circle"></div>
          </div>
          <div class="flex_column">
            <img :src="hzlj" class="pzh_com_img" />
            <div style="font-size: 18px; color: #464646;margin-left: 12px;">货主累计</div>
          </div>
        </div>
        <div class="pzh_com_content_num">
          <div style="color: #464646;width:150px;text-align:center;">
            <span style="font-size: 42px; font-family: 'dincond';">{{wNum}}</span
            ><span style="font-size: 20px">万吨</span>
          </div>
          <div style="color: #464646;width:150px;margin:0 200px;text-align:center;">
            <span style="font-size: 42px; font-family: 'dincond';"
              >{{ordNum}}</span
            ><span style="font-size: 20px">单</span>
          </div>
          <div style="color: #464646;width:150px;text-align:center;">
            <span style="font-size: 42px; font-family: 'dincond';"
              >{{vhcNum}}</span
            ><span style="font-size: 20px">个</span>
          </div>
          <div style="color: #464646;width:150px;margin-left:200px;text-align:center;">
            <span style="font-size: 42px; font-family: 'dincond';"
              >{{shipNum}}</span
            ><span style="font-size: 20px">个</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
  
  <script>
import aEntityAPI from "@/api/sysAPI.js";
import vueSeamlessScroll from "vue-seamless-scroll";
import luyiLogin from "./luyiLogin";
import luyiReg from "./luyiReg";
import luyiForgetPass from "./luyiForgetPass";
import { mapActions, mapGetters } from "vuex";

import userAPI from "@/api/userAPI.js";




export default {
  components: {
    vueSeamlessScroll,luyiLogin,luyiReg,luyiForgetPass
  },
  data() {


    return {
      sliders:'',
      bannerImg:'',
      typeIndex:1,
      shipNum: "",
      ordNum: "",
      vhcNum: "",
      wNum: "",
      infoList:[],
      currentInfo:{},
     shipper:null,
     userInfo:null,
      team: null,
      info: require("@/assets/luyi/17.png"),
      zhishi: require("@/assets/luyi/zhishi.png"),
      fire: require("@/assets/luyi/fire.png"),
      huatong: require("@/assets/luyi/huatong.png"),
      phonePng: require("@/assets/luyi/phonePng.png"),
      aboutUs: require("@/assets/luyi/about_us_pic1.png"),
      qysl: require("@/assets/luyi/qysl.png"),
      ydlj: require("@/assets/luyi/ydlj.png"),
      sjsl: require("@/assets/luyi/sjsl.png"),
      hzlj: require("@/assets/luyi/hzlj.png"),
      detail_grid_total: 0,

      validData: [],
      detail_grid_pageSize: 20,
      detail_grid_pageNo: 1,
      defaultOption: {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },

      bannerList: [require("@/assets/luyi/文件@2x.png")],
      comBannerTime: 10000,
      partnerList: [],
      companyDto: {},
      showNewList: [],
    };
  },

  // computed: {
  //       ...mapGetters({
  //           userinfo: "user/userinfo",
  //           sysConfig: "config/sysConfig",
  //           shipper: "user/shipper"
  //       })
  //   },


  methods: {
    showNewDetail(item, index) {
      var url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/luyi.html#/luyiNewsDetail?id=" +
        item.id;
      window.open(url);
    },

    detail_pageChange() {
      this.detailSearch();
    },
    detailSearch() {
      console.log("111");
    },

    handleBefore() {
      console.log("handleBefore");
      const index = this.infoList.findIndex((item) => item.id === this.currentInfo.id);
      if (index > 0) this.currentInfo = this.infoList[index - 1];
      console.log(index,"handleBefore",this.currentInfo);
    },

    handleNext() {
      console.log("handleNext");
      const index = this.infoList.findIndex((item) => item.id === this.currentInfo.id);
      if (index < this.infoList.length - 1) this.currentInfo = this.infoList[index + 1];
      console.log(index,"handleBefore",this.currentInfo);
    },

    getListWebSiteBroadConfigs(){
      aEntityAPI
        .request("BroadCastService", "listWebSiteBroadConfigs")
        .then((res) => {
          if (res.data.success) {
            this.sliders = res.data.data
            console.log(this.sliders,"sliders")

          }
        });
    },






    getHomeDataInfo() {
      let that = this;
      aEntityAPI
        .request("HomePageConfigService", "getHomePageDataInfo")
        .then((res) => {
          if (res.data.success) {
            var dataDict = res.data.data;
            this.bannerImg = dataDict.bannerImg
            if (
              dataDict.partnerList != null &&
              dataDict.partnerList.length > 0
            ) {
              var dataList = dataDict.partnerList;
              that.partnerList = [];
              if (dataList.length > 2) {
                var tempList = [];
                for (let index = 0; index < dataList.length; index++) {
                  const element = dataList[index];
                  tempList.push(element);
                  if (index % 2 == 1) {
                    that.partnerList.push(tempList);
                    tempList = [];
                  } else {
                    if (index == dataList.length - 1) {
                      that.partnerList.push(tempList);
                    }
                  }
                }
              } else {
                that.partnerList.push(dataList);
              }
            }
            if (
              dataDict.companyDes != null &&
              dataDict.companyDes.contantDsc != null
            ) {
              dataDict.companyDes.contantDsc =
                dataDict.companyDes.contantDsc.replaceAll(
                  "<p>",
                  "<p style='color:#fff;font-size:calc(100vw / 1920 * 18) ; line-height:calc(100vh / 1080  * 40)'>"
                );

              that.companyDes = dataDict.companyDes;
            }

            // that.shipNum = dataDict.shipNum ? dataDict.shipNum : 0; //注册货主
            // that.vhcNum = dataDict.vhcNum ? dataDict.vhcNum : 0; //注册车辆
            // that.wNum = dataDict.wNum ? dataDict.wNum : 0; // 年运量
            // that.ordNum = dataDict.ordNum ? dataDict.ordNum : 0; //年运单数
            that.$set(that,'shipNum',dataDict.shipNum ? dataDict.shipNum : 0)
            that.$set(that,'vhcNum',dataDict.vhcNum ? dataDict.vhcNum : 0)
            that.$set(that,'wNum',dataDict.wNum ? dataDict.wNum : 0)
            that.$set(that,'ordNum',dataDict.shipNum ? dataDict.ordNum : 0)

            // if (dataDict.allNews != null && dataDict.allNews.length > 0) {

            //   that.showNewList =dataDict.allNews;
            // }

            // if (dataDict.djNews != null && dataDict.djNews.length > 0) {

            //   that.showNewList .push(dataDict.djNews);
            // }
          }
        });
    },
    getluyiInfoList() {
      let that = this;
      var code =
        "'NEWS_TYPE_COMPANY','NEWS_TYPE_MEDIA','NEWS_TYPE_INDUSTRY','NEWS_TYPE_POLICY'";
      

      let params = {
        start: 0,
        limit: 999,
        q: {
          dictCode: code,
          fields: [
            "id",
            "title",
            "intro",
            "category",
            "categoryId",
            "coverImg",
            "releaseTime",
            "status",
            "content",
          ],
        },
      };

      aEntityAPI
        .request("NewsService", "getYgxNewsList", {
          qp: JSON.stringify(params),
        })
        .then((response) => {
          that.grid_loading = false;
          if (response.data.success) {
            that.moreTag = true;
            var dataList = response.data.data;
            this.infoList = dataList;
            this.currentInfo = dataList[0]
            console.log(this.currentInfo,'currentInfo')
          } else {
            that.$message.error("查询新闻出错");
          }
        });
    },
   
  },
  created(){
    if(JSON.stringify(this.$route.query) != "{}")
    {
      console.log(this,JSON.stringify(this.$route.query),'thisthis',typeof(this.$route.query.typeIndex),this.$route.query.typeIndex==2)
      this.$set(this,"typeIndex",this.$route.query.typeIndex)
      console.log(this,'createdthis')
    }
    
  },
  mounted() {
    this.shipper = JSON.parse(localStorage.getItem("shipper"));
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getluyiInfoList();
    this.getListWebSiteBroadConfigs();
   
    this.getHomeDataInfo();
    // this.getInfo4Index();
    console.log(this, "this111111");
  },
};
</script>
  <style scoped>
:deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
  background-color: #3fae73 !important;
}


.commerce_data {
  width: 100%;
  height: 587px;
  /* margin-top: -240px; */
}
.distribution {
  width: 100%;
  box-sizing: border-box;
  padding: 80px 60px 0;
  height: 835px;
  /* margin-top: 120px; */
  background: no-repeat center/cover url("../assets/luyi/distribution_back.png");
}


.flex_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  height: 100px;
  margin: 0 auto;
}
.flex_column {
  width: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}


.pzh_information {
  background: #f1f1f1;
  width: 100%;
  /* margin-top:-300px; */
}

.platform_left_img {
  width: 100px;
  height: 27px;
  margin-right: 18px;
}

.pzh_com_content_num {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
}

.pzh_com_pipeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  
  margin-top: -30px;
}
.pzh_com_icon {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pzh_com_img {
  width: 78px;
  height: 80px;
  margin-bottom: 12px;
}
.pzh_com_circle {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  border: 1px solid #60638d;
}
.pzh_com_line {
  width: 150px;
  border-bottom: 1px #b9b9b9 dotted;
}

.pzh_com_about {
  width: 1200px;
  margin: 0 auto;
  text-align: right;
  /* position: relative; */
}

.pzh_com_about_left {
  margin-top: 54px;
  width: 617px;
  height: 516px;
  z-index: 999;
  background-image: url("../assets/luyi/about_us_pic2.png");
  background-size: 100% 100%;
  position: relative;
  top: -511px;
}
.pzh_com_about_left_content {
  padding: 34px 27px;
}
.pzh_com_about_right {
  width: 771px;
  height: 505px;
  /* position: relative;
    left: 428px;
    top: -80px; */
}

.platform_left_time {
  
  color: #9b9b9b;
  font-size: 20px;
  
}

.pzh_info_content {
  width: 1600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-bottom: 180px;
}
.pzh_info_content_left {
  width: 380px;
  height: 428px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pzh_info_content_right {
  width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pzh_info_content_right_content {
  width: 380px;
  height: 428px;
  margin-left: 30px;
}
.pzh_info_content_right_remark {
  width: 380px;
  height: 214px;
  background: #fff;
}
.pzh_info_content_right_remark_top {
  width: 300px;
  height: 110px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.pzh_com_title_line {
  width: 100%;
  height: 2px;
  background-color: white;
  margin: 20px 0px;
}
.pzh_com_title_cha {
  color: #333333 !important;
  margin: 0px 20px;
  font-size: 36px;
  font-weight: 500 !important;
}
.pzh_com_title_eng {
  color: #9b9b9b;
  margin-top: 5px;
  font-family: Source Han Sans SC;
  font-size: 20px;
}
.pzh_com_title {
  display: flex;
  padding: 80px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}
.pzh_com_img {
  display: flex;
  align-items: center;
}
.pzh_com_content {
  width: 1200px;
  display: flex;
  flex-direction: column;
  padding-bottom: 1vh;
  align-items: center;
  justify-content: center;
  color: black;
  margin: 0 auto;
}


.platform {
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: #ffffff;
  width: 1200px;
  margin: 0 auto;
}

.platform_left {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.platform_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
}
.platform_right_more {
  margin-right: 8px;
  font-size: 20px !important;
  color: #9b9b9b !important;
  text-decoration: none !important;
}

.platform_left_title {
  width: 555px;
  color: #9b9b9b;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.arrow_icon {
  width: 25px;
  height: 25px;
}
.banner {
  width: 100%;
  height: 445px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.banner_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  height: 100%;
}
.banner_left {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.banner_left_title1 {
  color: #fff;
  font-size: 48px;
  margin-bottom: 33px;
}
.banner_left_title2 {
  color: #fff;
  font-size: 36px;
}
.banner_right {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 360px;
  background: #ffffff;
}

.input_cell {
  width: 240px;
  height: 36px !important;
}
</style>