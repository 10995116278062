<template>
  <div class="hp_news_detail_page">
    <div class="hp_news_detail_page_top">
        <div  class="hp_news_detail_page_top_title">{{newsDetail.title}}</div>
        <div  class="hp_news_detail_page_top_time">{{newsDetail.releaseTime}}</div>
    </div>
    <div class="hp_news_detail_page_body" v-html="newsDetail.content"></div>
  </div>
</template>


<script>
import aEntityAPI from "@/api/sysAPI.js";

export default {
  components: {},

  data() {
    return {
      newsId:null,
      newsDetail: {}, // 展示的新闻
      
    };
  },

  created() {},

  mounted() {
    let that = this;
    if (this.$route.query && this.$route.query.id != null) {
        this.newsId = this.$route.query.id;
    }

    that.gethpNewsDetail();
  },

  methods: {

    
    //获取云共享企业新闻列表
    gethpNewsDetail() {
      let that = this;
      

      aEntityAPI
        .request("NewsService", "getYgxNewsDetail", {id:that.newsId})
        .then((response) => {
          this.grid_loading = false;
          if (response.data.success) {
            this.moreTag = true;
            that.newsDetail = response.data.data;
            
          } else {
            this.$message.error("查询新闻出错");
          }
        });
    },
  },
};
</script>


<style>
.hp_news_detail_page {
  display: flex;
  width: 100%;
  background-color: #F1F1F1;
  flex-direction: column;
  align-items: center;
  padding-bottom: 160px;
}

.hp_news_detail_page_top{
    display: flex;
  width: 1200px;
  flex-direction: column;
  align-items: left;
  padding-top: 80px;
  border-bottom: 1px solid #E5E5E5;
}


.hp_news_detail_page_top_title{
 font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 800;
  color: #333333;
  line-height: 40px;
}

.hp_news_detail_page_top_time{
     font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 100;
  color: #333333;
  line-height: 40px;
}


.hp_news_detail_page_body{
    margin: 40px 0px;
    flex: 2;
    min-height: 600px;
    width: 1200px;
}
</style>