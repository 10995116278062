<template>
  <div class="pzh_body_index">
    <commerce></commerce>

    <luyi-supplier></luyi-supplier>

    <luyi-supply-hall></luyi-supply-hall>

    <luyi-about-us></luyi-about-us>

    <div class="pzh_information">
      <div class="pzh_com_title">
        <div class="pzh_com_title_cha">资讯中心</div>
        <div class="pzh_com_title_eng">Information center</div>
      </div>
      <div class="pzh_info_content">
        <div
          class="pzh_info_content_left"
          style="border-bottom: 2px solid #999999"
        >
          <div class="green_line"></div>

          <div style="width: 100%; height: 120px; margin-top: -160px">
            <div
              :class="
                item.selTag
                  ? 'pzh_news_page_body_left_item_active'
                  : 'pzh_news_page_body_left_item_none'
              "
              v-for="(item, index) in meunList"
              :key="index"
              @click="upReferData(index)"
            >
              <div
                :class="
                  item.selTag
                    ? 'pzh_news_page_body_left_point_a'
                    : 'pzh_news_page_body_left_point_b'
                "
              ></div>
              <div
                :class="
                  item.selTag
                    ? 'pzh_news_page_body_left_title_active'
                    : 'pzh_news_page_body_left_title'
                "
              >
                {{ item.title }}
              </div>
              <img
                v-if="index == 0"
                :src="item.selTag ? fire : fire_active"
                :style="item.selTag ? 'width: 22px; height: 22px; margin-left: 24%' : 'width: 22px; height: 22px; margin-left: 27%'"
              />
              <img
                v-else
                :src="item.selTag ? huatong_active : huatong"
                :style="item.selTag ? 'width: 22px; height: 22px; margin-left: 25%' : 'width: 22px; height: 22px; margin-left: 27%'"
              />
            </div>
          </div>
          <div
            @click="goNews()"
            style="display: flex; align-items: center; margin-bottom: 10px"
          >
            <span style="font-size: 16px">查看所有新闻</span>
            <img
              :src="zhishi"
              style="width: 22px; height: 22px; margin-left: 10px"
            />
          </div>
        </div>

          <div class="pzh_info_content_right">
            <block v-for="(item, index) in showNewList" :key="index" style="width:33%">
            <div
              @click="showNewDetail(item, index)"
              class="pzh_info_content_right_content"
            >
              <img :src="item.coverImg" style="width: 100%; height: 204px" />
              <div class="pzh_info_content_right_remark">
                <div class="pzh_info_content_right_remark_top">
                  <div style="width: 75%; font-size: 16px">
                    {{ item.title }}
                  </div>
                  <div style="width: 25%">
                    <div style="text-align: right; font-size: 32px">
                      {{
                        item.releaseTime
                          ? item.releaseTime.substring(8, 10)
                          : ""
                      }}
                    </div>
                    <div
                      style="text-align: right; font-size: 12px; color: #999999"
                    >
                      {{
                        item.releaseTime ? item.releaseTime.substring(0, 7) : ""
                      }}
                    </div>
                  </div>
                </div>
                <div
                  style="
                    width: 90%;
                    margin: 0px auto;
                    height: 1px;
                    border-bottom: 1px solid #efefef;
                  "
                ></div>

                <div class="flex_row">
                  <div class="black_line"></div>
                  <div style="display: flex; align-items: center">
                    <span style="font-size: 16px">了解更多</span>
                    <img
                      :src="zhishi"
                      style="width: 22px; height: 22px; margin-left: 10px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </block>
          </div>
    
      </div>
      <div class="pzh_information_img">
        <div @click="getNewList(1)" v-if="news_total>0" :class="iconFlag ==1 ? 'pzh_information_icon_active' : 'pzh_information_icon'"></div>
        <div @click="getNewList(2)" v-if="news_total>3" style="margin:0 14px;" :class="iconFlag == 2 ? 'pzh_information_icon_active' : 'pzh_information_icon'"></div>
        <div @click="getNewList(3)" v-if="news_total>6" :class="iconFlag == 3 ? 'pzh_information_icon_active' : 'pzh_information_icon'"></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import useChinaMap from "@/hooks/useChinaMap";
/**
 ** 可视化地图
 */
useChinaMap("chinaMap");
</script>
<script>
import aEntityAPI from "@/api/sysAPI.js";
import vueSeamlessScroll from "vue-seamless-scroll";

import userAPI from "@/api/userAPI.js";
import luyiAboutUs from "./luyiAboutUs.vue";
import { mapActions, mapGetters } from "vuex";
import commerce from "./commerce.vue";
import luyiSupplyHall from "./luyiSupplyHall.vue";
import luyiSupplier from "./luyiSupplier.vue";
export default {
  components: {
    vueSeamlessScroll,
    luyiAboutUs,
    luyiSupplyHall,
    commerce,
    luyiSupplier
  },
  
  data() {
    return {
      pageSize: 3,
      pageNo: 0,
shipper:null,
      timeTicket: null,
      iconFlag:1,
      performInterval: 60,
      showNewList: [], // 展示的新闻

      vhcLineUrl: "/dvs.html#/hpVhcLineMap", //车辆线路
      meunList: [
        {
          selTag: true,
          code: "",
          title: "最新资讯",
        },
        {
          selTag: false,
          code: "'NEWS_TYPE_INDUSTRY'",
          title: "行业动态",
        },
      ],
      loginForm: {
        loginName: "",
        password: "",
      },

      LoginForm: {
        loginName: "",
        password: "",
        smsCode: "",
        verify: "",
      },
      getSmsCodeName: "获取短信验证码",
      getSmsCodeStatus: false,
      fullscreenLoading: false,
      showVerify: false,
      verifyUrl:
        window.location.protocol +
        "//" +
        window.location.host +
        process.env.VUE_APP_BACKEND_URL_PROXY +
        "/verify?randid=" +
        Math.abs(Math.sin(new Date().getTime())),

      rules: {
        loginName: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "change",
          },
        ],
        smsCode: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "change",
          },
        ],
        verify: [
          {
            required: true,
            message: "请输入图片验证码",
            trigger: "change",
          },
        ],
      },
      shipNum: "",
      ordNum: "",
      vhcNum: "",
      wNum: "",
      infoList:[],
      currentInfo:{},
      news_total:0,
      info: require("@/assets/luyi/17.png"),
      zhishi: require("@/assets/luyi/zhishi.png"),
      fire: require("@/assets/luyi/fire.png"),
      fire_active: require("@/assets/luyi/fire_active.png"),
      huatong_active: require("@/assets/luyi/huatong_active.png"),
      huatong: require("@/assets/luyi/huatong.png"),
      phonePng: require("@/assets/luyi/phonePng.png"),
      aboutUs: require("@/assets/luyi/about_us_pic1.png"),
      qysl: require("@/assets/luyi/qysl.png"),
      ydlj: require("@/assets/luyi/ydlj.png"),
      sjsl: require("@/assets/luyi/sjsl.png"),
      hzlj: require("@/assets/luyi/hzlj.png"),
      detail_grid_total: 0,

      validData: [],
      detail_grid_pageSize: 20,
      detail_grid_pageNo: 1,
      defaultOption: {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },

      bannerList: [require("@/assets/luyi/文件@2x.png")],
      comBannerTime: 10000,
      partnerList: [],
      companyDto: {},
      showNewList: [],
      platformList: [
        {
          title: "招标信息",
          code: "tender",
          number: 0,
        },
        {
          title: "中标公示",
          code: "mark",
          number: 0,
        },
        {
          title: "加盟供应商",
          code: "carrier",
          number: 0,
        },
        {
          title: "变更公告",
          code: "change",
          number: 0,
        },
      ],
      markData: [],
      changeData: [],
      tenderData: [],
    };
  },
  methods: {
    showNewDetail(item, index) {
      var url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/luyi.html#/luyiNewsDetail?id=" +
        item.id;
      window.open(url);
    },

    detail_pageChange() {
      this.detailSearch();
    },
    detailSearch() {
      console.log("111");
    },
    getNewList(e){
      if(e == 1){
        this.pageNo =0
        this.iconFlag = 1
        this.getluyiNewsList()
      }else if(e ==2){
        console.log("111111111")
        this.pageNo =1
        this.iconFlag = 2
        this.getluyiNewsList()
      }else{
        this.pageNo =2
        this.iconFlag = 3
        this.getluyiNewsList()
      }
      
    },

    handleBefore() {
      console.log("handleBefore");
      const index = this.infoList.findIndex((item) => item.id === this.currentInfo.id);
      if (index > 0) this.currentInfo = this.infoList[index - 1];
      console.log(index,"handleBefore",this.currentInfo);
    },

    handleNext() {
      console.log("handleNext");
      const index = this.infoList.findIndex((item) => item.id === this.currentInfo.id);
      if (index < this.infoList.length - 1) this.currentInfo = this.infoList[index + 1];
      console.log(index,"handleBefore",this.currentInfo);
    },


    meunItemOver(indexTag) {
      let that = this;
      console.log(indexTag, "indexTag");
      for (let index = 0; index < that.meunList.length; index++) {
        var item = that.meunList[index];
        item.selTag = indexTag == index ? true : false;
      }
      console.log(that.meunList, "that.meunList");
      that.$set(that, "meunList", that.meunList);
    },

    upReferData(index) {
      console.log(index, "index");
      let that = this;
      that.meunItemOver(index);
      that.pageNo = 0;
      that.iconFlag = 1;
      that.showNewList = [];
      that.getluyiNewsList();
    },

    // 快讯
    getluyiInfoList() {
      let that = this;
      var code =
        "'NEWS_TYPE_COMPANY','NEWS_TYPE_MEDIA','NEWS_TYPE_INDUSTRY','NEWS_TYPE_POLICY'";
      

      let params = {
        start: 0,
        limit: 999,
        q: {
          dictCode: code,
          fields: [
            "id",
            "title",
            "intro",
            "category",
            "categoryId",
            "coverImg",
            "releaseTime",
            "status",
            "content",
          ],
        },
      };

      aEntityAPI
        .request("NewsService", "getYgxNewsList", {
          qp: JSON.stringify(params),
        })
        .then((response) => {
          that.grid_loading = false;
          if (response.data.success) {
            that.moreTag = true;
            var dataList = response.data.data;
            this.infoList = dataList;
            this.currentInfo = dataList[0]
            console.log(this.currentInfo,'currentInfo')
          } else {
            that.$message.error("查询新闻出错");
          }
        });
    },

    //获取云共享企业新闻列表
    getluyiNewsList() {
      let that = this;
      var code =
        "'NEWS_TYPE_COMPANY','NEWS_TYPE_MEDIA','NEWS_TYPE_INDUSTRY','NEWS_TYPE_POLICY'";
      console.log("item");
      that.meunList.forEach((item) => {
        console.log("item", item);
        if (item.selTag && item.code != "") {
          code = item.code;
        }
      });

      let params = {
        start: this.pageSize * this.pageNo,
        limit: this.pageSize,
        q: {
          dictCode: code,
          fields: [
            "id",
            "title",
            "intro",
            "category",
            "categoryId",
            "coverImg",
            "releaseTime",
            "status",
            "content",
          ],
        },
      };

      aEntityAPI
        .request("NewsService", "getYgxNewsList", {
          qp: JSON.stringify(params),
        })
        .then((response) => {
          that.grid_loading = false;
          if (response.data.success) {
            that.moreTag = true;
            that.news_total = response.data.total
            var dataList = response.data.data;
            if (dataList.length > 0) {
              that.showNewList = dataList;
              if (dataList.length >= that.pageSize) {
                that.pageNo = that.pageNo + 1;
              } else {
                that.moreTag = false;
              }
            } else {
              that.moreTag = false;
              // that.$message.sccess("已加载全部数据！");
            }
          } else {
            that.$message.error("查询新闻出错");
          }
        });
    },



    getHomeDataInfo() {
      let that = this;
      aEntityAPI
        .request("HomePageConfigService", "getHomePageDataInfo")
        .then((res) => {
          if (res.data.success) {
            var dataDict = res.data.data;
            if (
              dataDict.partnerList != null &&
              dataDict.partnerList.length > 0
            ) {
              var dataList = dataDict.partnerList;
              that.partnerList = [];
              if (dataList.length > 2) {
                var tempList = [];
                for (let index = 0; index < dataList.length; index++) {
                  const element = dataList[index];
                  tempList.push(element);
                  if (index % 2 == 1) {
                    that.partnerList.push(tempList);
                    tempList = [];
                  } else {
                    if (index == dataList.length - 1) {
                      that.partnerList.push(tempList);
                    }
                  }
                }
              } else {
                that.partnerList.push(dataList);
              }
            }
            if (
              dataDict.companyDes != null &&
              dataDict.companyDes.contantDsc != null
            ) {
              dataDict.companyDes.contantDsc =
                dataDict.companyDes.contantDsc.replaceAll(
                  "<p>",
                  "<p style='color:#fff;font-size:calc(100vw / 1920 * 18) ; line-height:calc(100vh / 1080  * 40)'>"
                );

              that.companyDes = dataDict.companyDes;
            }
            that.shipNum = dataDict.shipNum ? dataDict.shipNum : 0; //注册货主
            that.vhcNum = dataDict.vhcNum ? dataDict.vhcNum : 0; //注册车辆
            that.wNum = dataDict.wNum ? dataDict.wNum : 0; // 年运量
            that.ordNum = dataDict.ordNum ? dataDict.ordNum : 0; //年运单数
          }
        });
    },

    getInfo4Index() {
      aEntityAPI.request("NoticeService", "getInfo4Index", {}).then((res) => {
        if (res.data.success) {
          for (let key in res.data.data) {
            const index = this.platformList.findIndex((o) => o.code == key);
            this.$set(this.platformList[index], "number", res.data.data[key]);
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    goNews() {
      console.log("1111111111111111");
      var url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/luyi.html#/luyiNews";
      window.location.href = url;
      document.documentElement.scrollTop = 0;
    },
  },

  mounted() {
    let that = this;


    that.shipper = JSON.parse(localStorage.getItem("shipper"));
    that.team = JSON.parse(localStorage.getItem("team"));
    that.getluyiNewsList();
    that.getluyiInfoList();
    that.getHomeDataInfo();
    // that.getInfo4Index();
    console.log(that, "this111111");
  },
};
</script>
<style scoped>
:deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
  background-color: #3fae73 !important;
}

.flex_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 95px;
  margin: 0 auto;
}


.pzh_news_page_body_left_item_active {
  background-image: url("../assets/luyi/greenBg.png");
  background-size: 100% 100%;
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 60px;
  align-items: center;
}

.pzh_news_page_body_left_item_none {
  background-size: 100% 100%;
  background: #ffffff;
  display: flex;
  width: 96.5%;
  flex-direction: row;
  height: 60px;
  align-items: center;
}

.pzh_news_page_body_left_point_a {
  width: 6px;
  height: 6px;
  background: #ffffff;
  margin: 0 8% 0 8%;
}

.pzh_news_page_body_left_point_b {
  width: 6px;
  height: 6px;
  background: #00343f;

  margin: 0 8% 0 8%;
}

.pzh_news_page_body_left_title_active {
  font-size: 16px;
  font-family: Source Han Sans SC;
  width: 33%;
  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
}
.pzh_news_page_body_left_title {
  font-size: 16px;
  width: 33%;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  margin: 0 !important;
}

.pzh_info_content_tab {
  width: 300px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.green_line {
  width: 20px;
  height: 4px;
  background: #3fae73;
}
.black_line {
  width: 20px;
  height: 3px;
  background: #00343f;
}
.news_text {
  width: 556px;
  margin-left: 30px;
}
.news_text1 {
  width: 408px;
  margin-left: 30px;
}
.news {
  margin-bottom: 100px;
  width: 1201px;
  height: 450px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.news_bar:nth-child(2) {
  margin-top: -6px;
  width: 651px;
  height: 96px;
  background: #ffffff;
  display: flex;
  margin-bottom: 20px;
}
.news_title {
  width: 300px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-family: Source Han Sans SC;
  color: #333333;
  line-height: 50px;
}
.news_date {
  width: 96px;
  background: #184b95;
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 96px;
  text-align: center;
}
.news_date1 {
  width: 90px;
  height: 90px;
  background: #d0d9e7;
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 96px;
  text-align: center;
}
.news_bar {
  overflow: hidden;
  width: 529px;
  height: 90px;
  background: #ffffff;
  display: flex;
  margin-bottom: 20px;
}
.news_img {
  width: 650px;
}
.supply_hall {
  width: 100%;
  /* margin-top: -250px;; */
}
.abput_us {
  background: #ffffff;
  width: 100%;
  height: 979px;
  /* margin-top:-110px; */
}

.pzh_information {
  background: #f1f1f1;
  width: 100%;
  /* margin-top:-300px; */
}
.creditCarousel {
  background: #ffffff;
  box-shadow: 0px 0px 12px 1px rgba(25, 25, 25, 0.06);
  width: 1200px;
  height: 370px;
  margin: 0 auto;
  overflow: hidden;
}


.pzh_com_content_num {
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pzh_com_pipeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 20px;
  margin-top: -30px;
}
.pzh_com_icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.pzh_info_content {
  width: 83%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-bottom: 180px;
}
.pzh_info_content_left {
  
  width:25%;
  height: 408px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 3%;
}
.pzh_info_content_right {
 
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.pzh_info_content_right_content {
  width: 90%;
  height: 408px;
  margin-left: 10%;
  overflow: hidden;
}
.pzh_info_content_right_remark {
  width: 100%;
  height: 214px;
  background: #fff;
}
.pzh_info_content_right_remark_top {
  width: 90%;
  height: 105px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.ul-item {
  display: flex;
}
.pzh_partner_body {
  width: 1200px;
}
.pzh_com_title_line {
  width: 100%;
  height: 2px;
  background-color: white;
  margin: 20px 0px;
}
.pzh_com_title_cha {
  color: #333333 !important;
  margin: 0px 20px;
  font-size: 36px;
  font-weight: 500 !important;
}
.pzh_com_title_eng {
  color: #9b9b9b;
  margin-top: 5px;
  font-family: Source Han Sans SC;
  font-size: 20px;
}
.pzh_com_title {
  display: flex;
  padding: 80px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}

.pzh_com_content {
  width: 1200px;
  display: flex;
  flex-direction: column;
  padding-bottom: 1vh;
  align-items: center;
  justify-content: center;
  color: black;
  margin: 0 auto;
}
.pzh_page_size {
  width: 100%;
  height: 100%;
}
.share {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.tenderbut {
  position: absolute;
  top: 118px;
  right: 9px;
  padding: 0px 15px;
  margin-right: 40px !important;
  float: right;
  width: 93px;
  height: 32px;
  border: 2px solid #182a85 !important;
  border-radius: 15px;
  font-size: 16px !important;
  color: #182a85 !important;
}
.time {
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #7d7d7d;
}
.label {
  align-self: center;
  text-align: center;
  line-height: 30px;
  width: 69px;
  height: 30px;
  background: linear-gradient(90deg, #486ce4, #72b8ff);
  border-radius: 5px;
  font-size: 16px;
  color: #e4e6ed;
}
.tender_bars {
  display: flex;
  width: 1200px;
  flex-direction: row;
  flex-wrap: wrap;
}
.tenderWen {
  margin-top: 6vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 23px;
  font-weight: 600;
  color: #404040;
  line-height: 56px;
}
.tender_bar {
  position: relative;
  background: #fff url("../assets/luyi/16.png") no-repeat;
  cursor: pointer;
  background-size: 270px;
  background-position: 100%;
  width: 580px;
  height: 170px;
  margin: 12px 14px 7px 0;
  border: 3px solid #ffffff;
  box-shadow: 0px 3px 16px 0px rgba(21, 113, 179, 0.16);
  opacity: 0.97;
}
.platform-number {
  margin-top: 8px;
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 600;
  color: #182a85;
}
.platform-title {
  font-size: 14px;
  font-weight: 200;
  color: #818181;
}
.platform-box {
  width: 235px;
  font-size: 22px;
  align-self: center;
  text-align: center;
}
.platform-data {
  background: #ffffff;
  height: 100px;
  display: flex;
}
.platform-background-word {
  position: absolute;
  left: 35px;
  top: 39px;
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
}
.platform-background-img {
  position: relative;
  width: 255px;
  height: 100px;
  background-image: url("../assets/luyi/033@2x.png");
  background-size: 255px;
}
.pzh_menu_logo {
  width: 180px;
  margin-top: 22px;
  height: 31px;
  background: #182a85;
  border-radius: 5px;
  text-align: center;
}

.platform {
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: #ffffff;
  width: 1200px;
  margin: 0 auto;
}

.arrow_icon {
  width: 25px;
  height: 25px;
}
.banner {
  background-image: url("../assets/luyi/bg1.png");
  width: 100%;
  height: 445px;
}
.banner_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  height: 100%;
}
.banner_left {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.banner_left_title1 {
  color: #fff;
  font-size: 48px;
}
.banner_left_title2 {
  color: #fff;
  font-size: 36px;
}
.banner_right {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 360px;
  background: #ffffff;
}
.pzh_menu_large_img {
  width: 1202px;
  height: 357px;
  position: relative;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 357px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
/* .table-container{
  width: 1200px;
  height: 849px;
  background: #FFFFFF;
  box-shadow: 0px 0px 13px 3px rgba(91,95,108,0.16);
} */
.table-container {
  background-color: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  padding: 0 40px;
  padding-bottom: 10px;
  width: 1120px;
  margin-bottom: 150px;
}
.tabs {
  font-weight: 500;
  border-bottom: 1px solid #eee;
  margin-bottom: 55px;
  display: flex;
  justify-content: center;
  gap: 124px;
}
.tab {
  font-size: 14px;
  padding: 20px 0;
  cursor: pointer;
  color: #3fae73;
  border-bottom: 4px solid #3fae73;
}

.input_cell {
  width: 240px;
  height: 36px !important;
}

.hp_page_size {
  width: 100%;
  height: 100%;
}

.hp_main {
  width: calc(100vw);
  height: calc(100vh);
}

.hp_body_index {
  width: 100%;
  height: 100%;
  transition: all linear 0.5s;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
}

.white_color {
  color: #fff;
}

.hp_com_title {
  display: flex;
  flex-direction: column;
  margin-top: 15vh;
  padding-bottom: 1vh;
  align-items: center;
  justify-content: center;
  color: black;
}

.hp_com_title_en {
  font-size: 1.7rem;
  font-weight: 500;
  margin: 0px 20px;
}

.hp_com_title_line {
  width: 100%;
  height: 2px;
  background-color: black;
  margin: 1vh 0px;
}

.hp_com_title_cha {
  margin: 0px 20px;
  font-size: 1.7rem;
  font-weight: 400;
}

/* home *******start****** */
.hp_home {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* background-size: 100% 100%;
  background-image: url("../assets/hp/home_banner_bg.jpg"); */
}

.hp_home_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-size: 100% 100%;
  background-image: url("../assets/hp/home_banner_bg.jpg"); */
}

.hp_home_ord {
  width: 100%;
  height: 22%;
  z-index: 20;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.hp_home_ord_item {
  width: 15%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hp_home_ord_item_value {
  font-size: 2rem;
  font-weight: 700;
  color: white;
}

.hp_home_ord_item_line {
  width: 80%;
  height: 3px;
  background-color: white;
  margin: 5% 0;
}

.hp_home_ord_item_icon {
  height: 20%;
}

.hp_home_ord_item_title {
  font-size: 1.5rem;
  color: white;
  margin-top: 5%;
}

/* home *******end****** */

/* 物联服务 *******start****** */
.hp_wlfw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image: url("../assets/hp/wlfw_bg.png");
  background-size: calc(84vh) calc(13vh);
  background-position: 20% 36%;
  background-repeat: no-repeat;
}

.hp_wlfw_carousel {
  min-width: 1200px;
  width: 80%;
  margin-top: 12vh;
}

.hp_wlfw_arrow {
  display: flex;
  flex-direction: row;
  margin-top: 5vh;
}

.hp_wlfw_arrow_left {
  width: 120px;
  height: 40px;
  margin-right: 40px;
  cursor: pointer;
  background-image: url("../assets/hp/wlfw_l.png");
  background-size: 100% 100%;
}

.hp_wlfw_arrow_left:hover {
  background-image: url("../assets/hp/wlfw_l_s.png");
}

.hp_wlfw_arrow_right {
  width: 120px;
  height: 40px;
  cursor: pointer;
  background-image: url("../assets/hp/wlfw_r.png");
  background-size: 100% 100%;
}

.hp_wlfw_arrow_right:hover {
  background-image: url("../assets/hp/wlfw_r_s.png");
}

/* 物联服务 *******end****** */

/* 合作伙伴 *******start****** */
.hp_partner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.hp_partner_body {
  margin-top: 5vh;
  width: 1200px;
}

.creditCarousel {
  width: 1200px;
  height: 440px;
  margin: 0 auto;
  overflow: hidden;
}

.hp_partner ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}

.ul-item {
  display: flex;
}


/* 合作伙伴 *******end****** */

/* 联系我们   开始 */

.hp_link_us {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
}

.hp_link_us_top {
  display: flex;
  width: 100%;

  padding-top: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-size: 100% 100%;
  color: white;
  background-image: url("../assets/hp/link_us_bg.png");
}

.hp_link_us_body {
  display: flex;
  width: 1200px;
  flex: 2;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.hp_link_us_body_info {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.hp_link_us_body_item {
  display: flex;
  flex-direction: column;
}

.hp_link_us_body_item_title {
  font-size: 35px;
  font-family: SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  line-height: 8vh;
}

.hp_link_us_body_item_bottom {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.hp_link_us_body_item_img {
  width: 41px;
  height: 41px;
  margin-top: 20px;
  margin-right: 40px;
}
.hp_link_us_body_item_img img {
  width: 100%;
}

.hp_link_us_body_item_first {
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #333333;
  line-height: 8vh;
}

/* 联系我们   结束 */

/* 公司简介     开始     */

.hp_company {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
}

.hp_company_top {
  width: 100%;

  padding-top: 100px;

  background-image: url("../assets/hp/company_bg.png");
  background-size: 100% 100%;
}

.hp_company_top_title {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.hp_company_body {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 2;
}

.hp_company_body_contant {
  /* background-image: url("../assets/hp/company_d_bg.png"); */
  background-size: 50% 100%;
  background-position: calc(76.5vh) 0%;
  background-repeat: no-repeat;

  min-width: 1200px;
  min-height: 392px;
  width: calc(154vh);
  height: calc(50vh);

  display: flex;
  flex-direction: row;
  align-items: center;
}

.hp_company_body_desc {
  font-size: 1.5rem;
  padding: 2%;
  color: #fff;
  width: 45%;
  margin-left: 10%;
  height: 70%;
  background-image: url("../assets/hp/company_text_bg.png");
  background-size: 100% 100%;
  z-index: 20;
  text-align: justify;
}
/* 
.hp_company_body_desc >>> p{
  
    color: #fff;
} */

.hp_company_body_img_view {
  position: absolute;
  left: 45%;
  top: 15%;
  height: 71%;
  width: 50%;
}
/* 公司简介  结束   */

/* 新闻     开始 */

.hp_news {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/hp/new_body_bg.png");
  background-size: 100% 100%;
}

.hp_news_content {
  width: 70%;
  min-width: 1200px;
  height: 50%;
  max-height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.hp_news_left {
  width: 25%;
  display: flex;
  height: 100%;
  margin-right: 5%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.hp_news_left_title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.hp_news_left_bottom {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.hp_news_left_bottom_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.hp_news_left_bottom_btn_title {
  color: #111111;
  font-size: 14px;
  font-family: Source Han Sans SC;
  font-weight: 400;
}

.hp_news_left_bottom_line {
  width: 90%;
  height: 2px;
  background-color: #999999;
}

.hp_news_left_bottom_btn_img {
  height: 15px;
  width: 15px;
  margin-left: 10px;
}

.hp_news_right {
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.hp_news_body {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
}

.hp_news_body_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  height: 60px;
  cursor: pointer;
}

.hp_news_body_item_sel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  height: 60px;
  cursor: pointer;
  background-image: url("../assets/hp/news_pop.png");
  background-size: 100% 100%;
}

.hp_news_body_item_sel_dj {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  height: 60px;
  background-image: url("../assets/hp/news_pop_dj.png");
  background-size: 100% 100%;
}

.hp_news_body_item_space {
  width: 6px;
  height: 6px;

  margin: 0px 20px;
}

.row_common {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.hp_news_body_item_title {
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
}

.hp_news_body_item_img {
  width: 24px;
  height: 24px;
  float: right;
  margin-right: 40px;
}

.common_title_sec {
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #111111;
}

.common_sub_title_sec {
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #111111;
  margin: 10px 0;
}

.common_space_sec {
  width: 20px;
  height: 4px;
}

.distribution {
  box-sizing: border-box;
  width: 100%;
  padding: 80px 60px 0;
  background: no-repeat center/cover url("../assets/luyi/distribution_back.png");
}
.max-1200 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.title {
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.eng {
  margin-top: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-align: center;
}
.map {
  width: 1200px;
  height: 800px;
  position: relative;
  right: 100px;
  bottom: 80px;
}
.pzh_information_img{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -150px;
  margin-left: 24%;
}

.pzh_information_icon_active{
  width: 10px;
  height: 10px;
  background: #3FAE73;
}
.pzh_information_icon{
  width: 8px;
  height: 8px;
  border: 2px solid #3FAE73;
}
</style>