<template>
  <div class="pzh_body">
    <div>

      <div style="background: #F1F1F1;width:100%">
        <div  class="pzh_menu_phone">
        <div class="pzh_menu_phone_div">
          <div class="pzh_menu_phone_cell">
            <img :src="phoneImg" class="pzh_menu_phone_img" />
            <div class="pzh_menu_phone_num">客服电话：{{ dataDto.phone }}</div>
          </div>
          <!-- <div class="pzh_menu_phone_cell" style="margin-left:50px;">
            <img :src="phonePng" class="pzh_menu_phone_img" />
            <div class="pzh_menu_phone_num">在线客服</div>
          </div> -->
        </div>

        <div class="pzh_login">
            
          <span v-if="userInfo" style="color: #3FAE73;">{{userInfo.realName ? userInfo.realName : userInfo.name}}</span>
          <!-- <span style="cursor: pointer;" v-else>请登录</span> -->
          &nbsp;
          <span v-if="userInfo" style="cursor: pointer;font-size:10px;margin-left:5px;" @click="logout">[退出]</span>
          &nbsp;
        </div>

      </div>
      </div>
      
      <div  style="background: #ffffff;width:100%">

        <div class="pzh_menu">
        <div class="pzh_menu_logo_cell">
          <img :src="logoImg" class="pzh_menu_logo_img" />
          <div class="pzh_menu_logo_title">唐山市丰润区运输协会</div>
        </div>
        <div class="meumList">
          <template v-for="(item, index) in meumList">
          
            <div style="cursor: pointer;">
              <div
                class="pzh_menu_cell"
                :class="{ active: topIndex == item.indexTag }"
                @click="clickHomeMenum(item,item.route, item.indexTag)"
                :key="index"
              >
                {{ item.title }}
              </div>
            </div>
          </template>
        </div>
      </div>
      </div>
      <div class="pzh_container">
        <router-view></router-view>
      </div>
      <div class="pzh_footer">
        <div class="pzh_footer_top">
          <div class="flex_column">
            <img :src="logImg" style="width:110px;height:110px" />
            <div @click="goIndex()" class="pzh_footer_top_link"  style="color:#fff;margin-top:35px">友情链接 : 唐山市丰润区运输协会
</div>
          </div>
          <div class="flex_row">
            <div class="flex_column">
              <img :src="sjImg" style="width:120px;height:120px" />
              <div class="pzh_footer_top_link"  style="color:#fff;margin-top:25px">司机APP二维码</div>
            </div>
            <div class="flex_column" style="margin-left:50px;">
              <img :src="gzhImg" style="width:120px;height:120px" />
              <div class="pzh_footer_top_link"  style="color:#fff;margin-top:25px">协会公众号二维码</div>
            </div>
            <div class="flex_column" style="margin-left:50px;">
              <img :src="xcxImg" style="width:120px;height:120px" />
              <div class="pzh_footer_top_link"  style="color:#fff;margin-top:25px">协会小程序二维码</div>
            </div>
          </div>
          <div class="flex_column" style="height:145px;text-align:right;justify-content:space-between">
            
            <div>
              <div style="color:#fff;font-size:16px;padding-bottom:8px;width:100%">唐山丰润区运输协会电话</div>
              <div style="color:#fff;font-size:26px;opacity: 0.5;width:100%">{{dataDto.phone}}</div>
            </div>
            <div style="display:flex;flex-direction:column;width:100%;">
              <div style="color:#fff;font-size:16px;padding-bottom:8px;width:100%">公司邮箱</div>
              <div style="color:#fff;font-size:16px;opacity: 0.5;width:100%">{{dataDto.email}}</div>
            </div>
          </div>

        
          
        </div>

        <div class="pzh_footer_bottom"  v-html="dataDto.webLic">
        </div>
      
      </div>
    </div>
  </div>
</template>
<script>
import aEntityAPI from "@/api/sysAPI.js";
import userAPI from "@/api/userAPI.js";
import {
  mapActions,
  mapGetters,
} from "vuex";

export default {


  mounted(){
    this.shipper = JSON.parse(localStorage.getItem("shipper"));
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log("shipper",this.shipper,this)
    console.log("userInfo",this.userInfo)
    this.loadConfig();
    
  },
  data() {
    return {
      showProductTag: false,
      topIndex: 0,
      shipper: null,
      userInfo:null,
      phoneImg: require("@/assets/luyi/phoneImg.png"),
      phonePng: require("@/assets/luyi/phonePng.png"),
      logoImg: require("@/assets/luyi/logo@2x.png"),
      gzhImg: require("@/assets/luyi/gzh.jpg"),
      xcxImg: require("@/assets/luyi/xcx.jpg"),
      sjImg: require("@/assets/luyi/sj.png"),

      
      logImg: require("@/assets/luyi/log@2x.png"),
      largeImg: [require("@/assets/luyi/文件@2x.png")],
      user: null,
      dataDto: {
        phone: "",
        email:"",
        webLic:'',
        driverMiniApp:''

      },
      meumList: [
        { title: "首页", route: "luyi", indexTag: 0, showTag: true },
        {
          title: "商会数据",
          route: "commerce",
          indexTag: 1,
          showTag: false,
        },
        {
          title: "企业分布",
          route: "luyiSupplier",
          indexTag: 2,
          showTag: false,
        },
        { title: "货源大厅", route: "luyiSupplyHall", indexTag: 3, showTag: false },
        { title: "关于我们", route: "luyiAboutUs", indexTag: 4, showTag: false },
        { title: "资讯中心", route: "luyiNews", indexTag: 5, showTag: false },
      ],
    };
  
  },
  methods: {
    ...mapActions(["user/logout"]),
    logout(){
      this["user/logout"]().then((data) => {
        if (data) {
         
          
          window.location.href = "/luyi.html#/luyi";
          window.location.reload();
        }
      });
    },
    goIndex(){
      var url =
          window.location.protocol +
          "//" +
          window.location.host +
          "/luyi.html#/" +
          'luyi';
        window.location.href = url;
        document.documentElement.scrollTop = 0;
    },
    clickHomeMenum(item,selector, index) {
      console.log(item,selector, index,'selector, index')
      this.topIndex = parseInt(index);
          var url =
          window.location.protocol +
          "//" +
          window.location.host +
          "/luyi.html#/" +
          selector;
        window.location.href = url;
        if(index == 1){
          document.documentElement.scrollTop = 200;
        }else{
          document.documentElement.scrollTop = 0;
        }
    },
    // login(){
    //   var url = window.location.protocol + "//" + window.location.host + "/luyi.html#/luyiLogin";
    //   window.open(url);
    // },
    loadConfig(){
      aEntityAPI.request("AppConfigService", "getConfigsByClsList", {clsList: JSON.stringify(["CompanyConfig"])})
          .then(res => {
            let dataList = res.data.data
            this.dataDto.webLic = dataList.CompanyConfig.webLic
            this.dataDto.phone = dataList.CompanyConfig.phone
            this.dataDto.email = dataList.CompanyConfig.email
            this.dataDto.driverMiniApp = this.__ftpPath +  dataList.CompanyConfig.driverMiniApp.filePath
            console.log(res,'res')
          })
                  
    },

   

    goHelp(){
      var url = window.location.protocol + "//" + window.location.host + "/luyi.html#/luyiHelp";
      window.open(url);
    },
    toRegister(){
      var url = window.location.protocol + "//" + window.location.host + "/luyi.html#/luyiRegister";
      window.open(url);
    }
  },
};
</script>
<style scoped>
/* .pzh_body{
  font-family: 'douyu';
} */

body{
  overflow: visible !important;
}

.flex_row{
  align-items:center;
  display:flex;
}
.flex_column{
  align-items:center;
  display:flex;
  flex-direction: column;
}
.pzh_footer_line {
  margin-left: 10%;
  width: 80%;
  height: 1px;
  background: #676767;
}
.pzh_footer_click {
  width: 100%;
  height: 90px;
  background: #404040;
  color: #c2c2c2;
  font-size: 16px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  text-align: center;
  line-height: 90px;
}
.pzh_footer {
  width: 100%;
  height: 278px;
  background: #2B303B;
}
.pzh_container {
  width: 100%;
  background-color: #f5f6fa;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.pzh_menu_img {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pzh_menu_logo_cell{
  display: flex;
  align-items: center;
}
.pzh_menu_logo_text {
  font-size: 40px;
  font-family: DOUYU;
  font-style: oblique;
  font-weight: 900;
  color: #3f3f3f;
}

.pzh_menu_logo_text2 {
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 300;
  color: #ffffff;
  line-height: 33px;
}

.pzh_menu_logo_img {
  width: 49px;
  height: 49px;
  margin-right: 11px;
}
.pzh_menu_logo_title{
  width: 320px;
  font-size: 24px;
  font-family: DOUYU;
  font-weight: normal;
  color: #5B5F6C;
}
.meumList{
  align-items: right;
  display: flex;
}
.meumList div{
  text-align: right;
}
.meumList div .active{
  color: #3FAE73;
}
.pzh_menu_cell {
  padding-bottom: 5px;
  cursor: pointer;
  font-size: 18px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  margin-left: 50px;
}

.pzh_menu_cell:hover {
  color: #3FAE73 !important;
}

.pzh_menu_phone {
  width: 1200px;
  background: #F1F1F1;
  box-sizing: border-box;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
.pzh_menu_phone_img {
  width: 12px;
  height: 12px;
  color: #999999;
  margin-right: 5px;
}
.pzh_login {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pzh_menu {
  width: 1200px;
  box-sizing: border-box;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  background: #ffffff;
}
.pzh_menu_phone_num {
  font-family: Microsoft YaHei;
  color: #666666;
}

.pzh_menu_phone_cell{
  display: flex;
  align-items: center;
}

.pzh_menu_phone_div{
  display: flex;
  align-content: center;
}

.pzh_footer_top{
  width: 1200px;
  height: 236px;
  margin: 0 auto;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: space-around;
}

.pzh_footer_bottom{
  width: 100%;
  height: 42px;
  margin: 0 auto;
  color: #c2c2c2;
  font-size: 14px;
  background: #15181D;
  display: flex;
  align-items: center;
  justify-content: center;

}
</style>