<template>
    <div style="height: 652px;" class="share">
        <el-form :model="RegistForm" status-icon :rules="rules" ref="RegistForm" class="demo-RegistForm" style="width: 354px;">
            <h4 class="register_title">供应商注册</h4>
            <h2 class="register_text">账号信息</h2>
            <el-form-item prop="registName">
                <el-input type="input" v-model="RegistForm.registName" autocomplete="off" placeholder="请输入11位有效手机号"></el-input>
            </el-form-item>
            <el-form-item prop="registPassword">
                <el-input type="password" v-model="RegistForm.registPassword" autocomplete="off" placeholder="请输入8-16位包含数字和大小写字母组成的密码" show-password></el-input>
            </el-form-item>
            <el-form-item prop="verify" style="margin-top: 22px">
                <el-row>
                    <el-col :span="16">
                        <el-input type="input" placeholder="图片验证码（区分大小写）" v-model="RegistForm.verify" autocomplete="off"></el-input>
                    </el-col>
                    <el-col :span="8" style="height:47px">
                        <el-image @click="changeImgCode" fit="fill" :src="verifyUrl" style="width: 100%;height:47px;cursor: pointer;float:right" />
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item prop="registCode">
                <el-row type="flex" align="middle">
                    <el-col :span="14">
                        <el-input v-model="RegistForm.registCode" class="putCode" placeholder="请输入验证码" maxlength="6"></el-input>
                    </el-col>
                    <el-col :span="10">
                        <el-button type="primary" plain :disabled="this.getSmsCodeStatus" style="width:100%;height:43px" @click="getSmsCode">{{this.getSmsCodeName}}
                        </el-button>
                    </el-col>
                </el-row>

            </el-form-item>
            <el-form-item>
                <el-button type="primary" style="width: 353px;height: 48px;" @click="submitForm" :loading="loading">下一步</el-button>
            </el-form-item>
        </el-form>
        <template>
            <div class="success-form">
                <el-row type="flex" justify="center" align="middle">
                    <!-- <el-col> -->
                    <div class="noralTitle divclass" v-html="downTimeMessage"></div>
                    <el-button type="text" @click="goToUserInfo" v-if="downTimeMessage">立即跳转</el-button>
                    <!-- </el-col> -->
                </el-row>
            </div>
        </template>
    </div>
</template>
<script>
import userAPI from "@/api/userAPI.js";
import { mapActions, mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
            userinfo: "user/userinfo",
            sysConfig: "config/sysConfig",
            shipper: "user/shipper",
            __menu: "user/menu",
        }),
    },
    data() {
        var checkAge = (rule, value, callback) => {
            if (!value) {
                callback(new Error("验证码不能为空"));
            }
            let re = /(^[\\\-0-9][0-9]*(.[0-9]+)?)$/; //判断字符串是否为数字//判断正整数/[1−9]+[0−9]∗]∗/
            if (!re.test(value)) {
                callback(new Error("请输入正确的验证码"));
            }else{
                callback()
            }
        };
        var pwdPowerLengthFunc = (rule, value, callback) => {
            var pwdRegex = new RegExp(
                "(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).{8,16}"
            );
            if (!pwdRegex.test(value)) {
                callback(
                    new Error("请输入8-16位包含数字和大小写字母组成的密码")
                );
            } else {
                callback();
            }
        };
        let checkMobile = (rule, value, callback) => {
            if (!value) {
                callback(new Error("手机号不能为空"));
            }
            let re = /(^[\\\-0-9][0-9]*(.[0-9]+)?)$/; //判断字符串是否为数字//判断正整数/[1−9]+[0−9]∗]∗/
            if (!re.test(value)) {
                callback(new Error("请输入数字值"));
            } else {
                if (value.length != 11) {
                    callback(new Error("手机号格式不正确"));
                } else {
                    callback();
                }
            }
        };
        return {
            loading: false,
            verifyUrl: null,
            getSmsCodeName: "获取短信验证码",
            getSmsCodeStatus: false,
            RegistForm: {
                registName: "",
                verify: "",
                registCode: "",
                registPassword: "",
            },
            downTimeMessage: "",
            rules: {
                registPassword: [
                    { validator: pwdPowerLengthFunc, trigger: "change" },
                ],
                registName: [{ validator: checkMobile, trigger: "change" }],
                registCode: [{ validator: checkAge, trigger: "change" }],
                verify: [ {required: true,message: "请输入图片验证码",trigger: "change",}],
            },
        };
    },
    methods: {
        ...mapActions(["user/login"]),
        changeImgCode() {
            this.RegistForm.verify = ""; //清空验证码

            this.verifyUrl =  window.location.protocol +
                    "//" +
                    window.location.host +
                    process.env.VUE_APP_BACKEND_URL_PROXY +
                    "/verify?randid=" +
                    Math.abs(Math.sin(new Date().getTime()));
        },
        getSmsCode() {
            let valid = true;
            this.$refs.RegistForm.validateField(["registName","verify"],(err) => {
                if (err != "") {
                    valid = false;
                }
            });

            if (valid) {
                let params = {
                    userImgVerifyCode: this.RegistForm.verify,
                    mobile: this.RegistForm.registName,
                    type: "reg",
                };

                userAPI.getSmsVerifyCode(params).then((response) => {
                    if (response.data.success) {
                        this.waitCodeTime();
                    } else {
                        let message =response.data.msg == null? "获取验证码失败": response.data.msg;
                        this.$message({ message: message,type: "error",});
                    }
                });
            }
        },

        waitCodeTime() {
            let waitSeconds = 60;
            this.getSmsCodeStatus = true;
            var interval = setInterval(() => {
                waitSeconds--;
                this.getSmsCodeName = waitSeconds + "s后重新获取";
                this.getSmsCodeStatus = true;
                if (waitSeconds < 1) {
                    this.getSmsCodeName = "没收到？重新获取";
                    this.getSmsCodeStatus = false;
                    clearInterval(interval);
                }
            }, 1000);
        },
        submitForm() {

            this.$refs.RegistForm.validate( valid => {
                if(valid){
                    const params = {
                        password: this.RegistForm.registPassword,
                        mobile: this.RegistForm.registName,
                        smsCode: this.RegistForm.registCode,
                    };
                    this.loading = true;
                    userAPI.registSubMit4Pzh(params).then( res => {
                        if(res.data.success){
                            this.$message({
                                message: "注册成功",
                                type: "success",
                                onClose: () => {
                                    this.successWaitCodeTime();
                                }
                            });
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
                }else{
                    console.log("error submit!!");
                }
            });
        },
         //注册成功倒计时
        successWaitCodeTime() {
            let waitSeconds = 10;

            var interval = setInterval(() => {
                waitSeconds--;
                this.downTimeMessage =
                    "您的账户资料不完善，请完善您的账户资料。<br/> 系统将会在" +
                    waitSeconds +
                    "秒后自动跳转至会员中心。";
                if (waitSeconds < 1) {
                    clearInterval(interval);
                    this.loading = false;
                    this.goToUserInfo();
                }
            }, 1000);
        },
        goToUserInfo() {
            this["user/login"]({
                loginName: this.RegistForm.registName,
                password: this.RegistForm.registPassword
            }).then(data => {
                if (data.success) {
                    window.location.href = "luyi.html#/luyiCertificate";
                }
            });
        },
    },
    mounted(){
        this.changeImgCode();
    }
};
</script>
<style >
    .register_text {
        padding: 43px 0 26px;
        font-size: 16px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        color: #333333;
    }
    .register_title {
        padding-top: 90px;
        text-align: center;
        font-size: 24px;
        font-family: Source Han Sans SC;
        font-weight: bold;
        color: #333333;
    }
    .putCode {
        width: 100% !important;
    }
    .el-input--mini .el-input__inner {
        width: 100%;
        height: 44px !important;
    }
</style>