<template>
    <div v-loading="loading">
        <div style="text-align: center;">
            <div class="share_style">
                <div style="width: 915px;">
                    <div class="detailsTitle">
                        {{notice.name}}
                    </div>
                    <el-row :gutter="20" style="margin-bottom: 40px;">
                        <el-col :span="notice.noticeType && notice.noticeType.value != 1?7:5" style="font-size: 14px;">
                            招标类型:{{ notice.bidType? notice.bidType.name: ( (notice.parent && notice.parent.bidType)?notice.parent.bidType.name:"111")}}
                        </el-col>
                        <el-col :span="notice.noticeType && notice.noticeType.value != 1?7:5" style="font-size: 14px;">
                            开放范围:{{notice.type?notice.type.name:""}}
                        </el-col>
                        <el-col v-show="notice.noticeType && notice.noticeType.value == 1" :span="7" style="font-size: 14px;">
                            报名时间:{{notice.signStart}}
                        </el-col>
                        <el-col  v-show="notice.noticeType && notice.noticeType.value == 1" :span="7" style="font-size: 14px;">
                            截止时间:{{notice.signEnd}}
                        </el-col>
                        <el-col v-show="notice.noticeType && notice.noticeType.value != 1" :span="7" style="font-size: 14px;">
                            发布时间:{{notice.createTime}}
                        </el-col>
                      </el-row>
                </div>
                <div style="width: 274px;">
                    <el-button type="primary" style="margin-top: 100px;font-size: 16px;" @click="signUp" v-if="notice.noticeType && notice.noticeType.value==1">报名</el-button>
                </div>
           </div>
        </div>
        <div class="share_style" >
            <div >
                <div class="description">
                    <div style="display:flex;justify-content:flex-start;margin: 35px 0 23px;font-weight: bold;">
                        <div style="width: 3px;height: 20px;background: #182A85;margin-top:3px"></div>
                        <div style="padding-left:7px;font-size: 20px;color: #000000;">招标说明</div>
                        <!-- <span style="color: #182A85;transform:translateY(-40px)">|</span><span style="padding-left:7px">联系方式</span> -->
                    </div>
                    <div >
                        <div style="width:92%;" v-html="notice.content"></div>
                    </div>

                </div>
                <div class="file">
                    <div style="display:flex;justify-content:flex-start;margin: 35px 0 23px;font-weight: bold;">
                        <div style="width: 3px;height: 20px;background: #182A85;margin-top:3px"></div>
                        <div style="padding-left:7px;font-size: 20px;color: #000000;">招标文件</div>
                        <!-- <span style="color: #182A85;transform:translateY(-40px)">|</span><span style="padding-left:7px">联系方式</span> -->
                    </div>
                    <div>
                        <div v-if="canSeeFiles">
                            <div  v-for='item in files' :key="item.id" style="background: rgb(128 128 128 / 11%);height: 56px;margin-bottom: 10px;width: 97%;">
                                <div>
                                <img class="picture" v-if='item.file.extension=="txt"' src="@/assets/luyi/TXT.png">
                                <img class="picture" v-if='item.file.extension=="xls"' src="@/assets/luyi/XLSX.png">
                                <img class="picture" v-if='item.file.extension=="png"' src="@/assets/luyi/TP.png">
                                <img class="picture" v-if='item.file.extension=="pdf"' src="@/assets/luyi/PDF.png">
                                <img class="picture" v-if='item.file.extension=="ppt"' src="@/assets/luyi/PPT.png">
                                <el-link style="margin: 0px 0 20px 18px;" v-model="item.file.id"  @click="downloadFile(item.file.guid)">{{item.file.fileName}}</el-link>
                                <!-- <el-link style="margin: 0px 0 20px 18px;position: relative;
                                left: 452px;" v-model="item.file.id" @click="downloadFile(item.file.guid)">下载</el-link> -->
                                </div>
                            </div>
                        </div >
                        <div v-else class="pzh_upload">
                            <div v-if="canNotSeeReason == 1">
                                <span  style="color: #000000;line-height: 143px;font-size:16px">
                                    入驻后才可以
                                </span>
                                <span style="color: blue;font-size:16px;cursor:pointer;" @click="view" >查看</span>
                            </div>
                            <div v-else>
                                <span  style="color: #000000;line-height: 143px;font-size:16px">
                                    只有中标方和发布方可以查看文件
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="information">
                <div class="titles">
                    <div style="display:flex;justify-content:flex-start;margin: 35px 0 23px;font-weight: bold;">
                        <div style="width: 3px;height: 20px;background: #182A85;margin-top:3px"></div>
                        <div style="padding-left:7px;font-size: 20px;color: #000000;">联系方式</div>
                        <!-- <span style="color: #182A85;transform:translateY(-40px)">|</span><span style="padding-left:7px">联系方式</span> -->
                    </div>
                </div>
                <table cellspacing="0" cellpadding="0" border="1" class="gs-table" style="width:97%;border-collapse: collapse;transform:translateX(-20px)">
                    <colgroup>
                      <col width="130px" >
                      <col width="280px">
                      <col width="130px" >
                      <col width="280px">
                    </colgroup>
                   
                    <tbody>
                        <tr>
                            <th>
                                <div class="gs-cell">联系方式</div>
                            </th>
                            <th>
                                <div class="gs-cell">{{notice.team && notice.team.shipper?notice.team.shipper.contact_name:""}}</div>
                            </th>
                            <th>
                                <div class="gs-cell">联系手机</div>
                            </th>
                            <th>
                                <div class="gs-cell">{{notice.team && notice.team.shipper?notice.team.shipper.contact_mobile:""}}</div>
                            </th>
                           
                        </tr>
                        <tr>
                            
                            <th>
                                <div class="gs-cell">联系座机</div>
                            </th>
                            <th>
                              <div class="gs-cell">{{notice.team && notice.team.shipper?notice.team.shipper.contact_mobile:""}}</div>
                            </th>
                            <th>
                                <div class="gs-cell">联系邮箱</div>
                            </th>
                            <th>
                              <div class="gs-cell">{{notice.team && notice.team.shipper?notice.team.shipper.email:""}}</div>
                            </th>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
           <div>
            <div class="company">
                <div style="height: 136px;text-align: center;">
                   <img class="information_img" src="@/assets/luyi/logo_new.png">
                   <div style="font-size: 16px;font-weight: 500;">{{notice.team && notice.team.shipper?notice.team.shipper.name:""}}</div>
                </div>
                <el-divider style="margin:0;"></el-divider>
                <div style="padding-left: 33px;">
                   <div style="font-size: 16px;">
                    <span style="margin-right: 24px;">注册地址</span>
                    <span>{{city?city.fullName:""}}</span>
                   </div> 
                   <div style="font-size: 16px;">
                    <span style="margin-right: 24px;">公司性质</span>
                    <span>{{notice.team && notice.team.shipper && notice.team.shipper.companyType?notice.team.shipper.companyType.name:""}}</span>
                   </div> 
                </div>
                <el-divider style="margin:0;"></el-divider>
                <div>
                    <div style="font-size: 16px;padding-left: 33px;">
                        <span style="margin-right: 24px;">招标浏览</span>
                        <span>{{notice.browseCount+1}}</span>
                       </div> 
                </div>
            </div>
           </div>
        </div>
        
    </div>
</template>
<script>
import noticeAPI from"./aes.js";
import aEntityAPI from "@/api/aEntityAPI.js";
 export default {
  
    data(){
        return{
            loading: false,
            shipper: null,
            team: null,
            city: null,
            notice:{
                team:{},
                content:''
            },
            files:[],
            TenderDetails:{
                title:'2023-2024年度国内干线运输项目招标公告',
                type:'货物'
            },
            tenderId:"",
            canSeeFiles: false,
            canNotSeeReason: null
        }
    },
    methods:{
        signUp(){
            if(this.notice && this.notice.status && this.notice.status.value != 3 ){
                this.$message.error("该公告不是发布状态，不允许报名")
                return;
            }
            if( !this.shipper){
                window.location.href = "/luyi.html#/luyiLogin";
                return;
            }
            if( this.shipper && !this.team){
                window.location.href = "/luyi.html#/luyiCertificate";
                return;
            }
            if(this.team.type=='供应方'){
                window.location.href = "/bid.html#/procure/noticeDetail/" + item.id + "?no=" + item.no;
                return;
            }else{
                this.$message.error("您不是供应商，无法报名")
                return;
            }
        },
        view(){
            var url = window.location.protocol + "//" + window.location.host + "/luyi.html#/luyiLogin";
            window.open(url);
        },
        downloadFile(guid) {
            console.log(guid,"guid");
            if(guid){
                window.open(window.location.protocol + '//' + window.location.host+  process.env.VUE_APP_BACKEND_URL_PROXY + "/file/get?guid=" + guid);
            }
        },
        detailsTender(){
            const _this = this;
            this.loading = true;
            aEntityAPI.request('MyNoticeService','getNoticeDetail',{'noticeId':this.tenderId})
            .then(res=>{
                this.loading = false;
                if(res.data.success){
                    _this.notice = res.data.data.notice;
                    _this.city = res.data.data.city;
                    _this.files = res.data.data.files;
                    _this.canSeeFiles = res.data.data.canSeeFiles;
                    _this.canNotSeeReason = res.data.data.canNotSeeReason;
                    _this.addBrowseCount(_this.tenderId);
                }
            })
        },
        addBrowseCount(id){
            aEntityAPI.request("NoticeService","addBrowseCount",{id: id}).then(res=>{
                if(res.data.success){

                }else{
                    this.$message.error(res.data.msg)
                }
            })
        }
    },
    mounted(){
        this.shipper = JSON.parse(localStorage.getItem("shipper"));
        this.team = JSON.parse(localStorage.getItem("team"));
        if (this.$route.query && this.$route.query.id != null) {
            this.tenderId = this.$route.query.id;
        }
        if(this.tenderId){
            this.detailsTender() 
        }
    }
}
</script>
<style>
.picture{
    position: relative;
    width: 27px;
    height: 33px;
    margin: 9px 0 0 10px;
}
.information_img{
    width: 75px;
    margin: 19px 0;
}
.titles{
margin: 35px 0 23px;
font-size: 22px;
font-family: Source Han Sans SC;
font-weight: bold;
color: #000000;
line-height: 32px; 
}
.pzh_upload{
transform: translateX(-25px);
text-align: center;
width: 828px;
height: 143px;
background: #D6D6D6;
border: 2px dotted #C8C8C8;
}
.information{
overflow: hidden;
padding-left: 67px;
margin-top: 13px;    
width: 848px;
height: 201px;
background: #FFFFFF;
margin-bottom: 80px;
}
.file{
overflow: hidden;
padding-left: 67px;
margin-top: 13px;
width: 848px;
height: 406px;
background: #FFFFFF;
}
.company{
margin-left: 8px;
width: 274px;
height: 350px;
background: #FFFFFF;  
}
.share_style{
display: flex;
justify-content: center;
}
.detailsTitle{
margin: 52px 0 24px;
font-size: 26px;
font-family: Source Han Sans SC;
font-weight: bold;
color: #3F3F3F;  
}
.description{
overflow: hidden;
padding-left: 67px ;
width: 848px;
min-height: 780px;
background: #FFFFFF;
}
</style>