import Vue from 'vue'
import Router from 'vue-router'
import luyiIndex from './luyiIndex.vue'
import commerce from './commerce.vue'
import luyiNews from './luyiNews.vue'
import luyiSupplier from './luyiSupplier.vue'
import luyiNewsDetail from './luyiNewsDetail.vue'
import luyiTenderDetails from './luyiTenderDetails.vue'
import luyiAboutUs from './luyiAboutUs.vue'
import luyiSupplyHall from './luyiSupplyHall.vue'
import luyiHelp from './luyiHelp.vue'
import luyiLogin from './luyiLogin.vue'
import luyiRegister from './luyiRegister.vue'
import luyiCertificate from './luyiCertificate.vue'
Vue.use(Router)


export default new Router({
    routes: [
        {path: '*', redirect: '/luyi'},
        {//系统入口页面，
            path: '/luyi/:searchValue?',
            name: 'luyi',
            props: true,
            meta: {
                title: '唐山市丰润区运输协会'
            },
            component: luyiIndex

        },
        {
            path: '/luyiLogin',
            name: 'luyiLogin',
            props: true,
            meta: {
                title: '登录'
            },
            component: luyiLogin

        },
        {
            path: '/luyiRegister',
            name: 'luyiRegister',
            props: true,
            meta: {
                title: '供应商注册'
            },
            component: luyiRegister

        },
        {
            path: '/luyiCertificate',
            name: 'luyiCertificate',
            props: true,
            meta: {
                title: '证件上传'
            },
            component: luyiCertificate

        },
        {
            path: '/luyiSupplier',
            name: 'luyiSupplier',
            props: true,
            meta: {
                title: '企业分布'
            },
            component: luyiSupplier

        },
        {
            path: '/commerce',
            name: 'commerce',
            props: true,
            meta: {
                title: '商会数据'
            },
            component: commerce

        },
        {
            path: '/luyiNews',
            name: 'luyiNews',
            props: true,
            meta: {
                title: '新闻中心'
            },
            component: luyiNews

        },
        {
            path: '/luyiNewsDetail/:id?',
            name: 'luyiNewsDetail',
            props: true,
            meta: {
                title: '新闻详情'
            },
            component: luyiNewsDetail

        },
        {
            path: '/luyiTenderDetails/:id?',
            name: 'luyiTenderDetails',
            props: true,
            meta: {
                title: '招标详情'
            },
            component: luyiTenderDetails

        },
        {
            path: '/luyiAboutUs',
            name: 'luyiAboutUs',
            props: true,
            meta: {
                title: '关于我们'
            },
            component: luyiAboutUs

        },
        {
            path: '/luyiSupplyHall',
            name: 'luyiSupplyHall',
            props: true,
            meta: {
                title: '货源大厅'
            },
            component: luyiSupplyHall

        },
        {
            path: '/luyiHelp',
            name: 'luyiHelp',
            props: true,
            meta: {
                title: '帮助中心'
            },
            component: luyiHelp

        }
    ]
})
