<template>
  <div class="pzh_body_index">



    <div class="supply_hall">
      <div class="pzh_com_title">
        <div class="pzh_com_title_cha">货源大厅</div>
        <div class="pzh_com_title_eng">Supply hall</div>
      </div>
      <div class="table-container">
        <div class="tabs">
          <div class="tab">运输企业</div>
        </div>
        <el-table
          :data="validData"
          style="width: 100%"
          :cell-style="{ height: '60px' }"
        >
          <el-table-column label="编号" width="140">
            <template slot-scope="scope">
              <span class="text-green">{{
                scope.$index + 1 > 9
                  ? scope.$index + 1
                  : "0" + (scope.$index + 1)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="企业名称" width="280">
            <template slot-scope="scope">
              <span class="text-green">{{ scope.row.shipperName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="industry" label="货物类型" width="240">
            <template slot-scope="scope">
              <span class="text-gray">{{ scope.row.industry }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="cityName" label="地址" width="300">
            <template slot-scope="scope">
              <span class="text-gray">{{ scope.row.cityName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="时间" width="160">
            <template slot-scope="scope">
              <span class="text-gray">{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="data_more">
          <el-pagination
            :current-page.sync="detail_grid_pageNo"
            :page-size.sync="detail_grid_pageSize"
            :total="detail_grid_total"
            background
            layout="total,prev, pager, next"
            @current-change="detail_pageChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>
  
  <script>
import vueSeamlessScroll from "vue-seamless-scroll";
import aEntityAPI from "@/api/sysAPI.js";
import shipperAPI from "./shipperAPI.js"
import {
  mapActions,
  mapGetters,
} from "vuex";

export default {
  components: {
    vueSeamlessScroll,
  },

 

  data() {
    return {
      team: null,
      info: require("@/assets/luyi/17.png"),
      zhishi: require("@/assets/luyi/zhishi.png"),
      fire: require("@/assets/luyi/fire.png"),
      huatong: require("@/assets/luyi/huatong.png"),
      phonePng: require("@/assets/luyi/phonePng.png"),
      aboutUs: require("@/assets/luyi/about_us_pic1.png"),
      qysl: require("@/assets/luyi/qysl.png"),
      ydlj: require("@/assets/luyi/ydlj.png"),
      sjsl: require("@/assets/luyi/sjsl.png"),
      hzlj: require("@/assets/luyi/hzlj.png"),
      detail_grid_total: 0,
      shipper:null,

      validData: [],
      detail_grid_pageSize: 10,
      detail_grid_pageNo: 1,
      companyType:'',
      defaultOption: {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
    };
  },
  methods: {


    detail_pageChange() {
      this.findShipperRanking();
    },
    detailSearch() {
      console.log("111");
    },

    findShipperRanking(){
      this.shipper = JSON.parse(localStorage.getItem("shipper"));
     
      let params = {
        'start': this.detail_grid_pageSize * (this.detail_grid_pageNo - 1),
        'limit': this.detail_grid_pageSize,
        'q': {}
      };
      
      aEntityAPI.request('ShipperService', 'findShipperRanking', {qp: JSON.stringify(params)}).then((res) => {
      console.log(res,'resres')
        if (res.data.success) {
          console.log(res.data,'aaa')
          this.validData = res.data.data
          this.detail_grid_total = res.data.total;

        } else {

        }
      });
      
    },

   
  },
  mounted() {
    this.findShipperRanking();
    this.shipper = JSON.parse(localStorage.getItem("shipper"));
    this.team = JSON.parse(localStorage.getItem("team"));
    
    console.log(this.shipper, "this111111");
  },
};
</script>
  <style scoped>
:deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
  background-color: #3fae73 !important;
}


.data_more {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 20px 0;
  justify-content: center;
}
.width-1200 {
  width: 1200px;
  margin: 0 auto;
}
.width-1200 .title {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  color: #ffffff;
}
.width-1200 .eng {
  text-align: center;
  font-size: 20px;
  color: #ffffff;
}

.text-green {
  color: #3fae73;
}
.text-gray {
  color: #9b9b9b;
}
.flex_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  height: 100px;
  margin: 0 auto;
}
.flex_column {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.supply_hall {
  width: 100%;
  height: 1200px;
  /* margin-top: -250px;; */
}


.pzh_com_title_cha {
  color: #333333 !important;
  margin: 0px 20px;
  font-size: 36px;
  font-weight: 500 !important;
}
.pzh_com_title_eng {
  color: #9b9b9b;
  margin-top: 5px;
  font-family: Source Han Sans SC;
  font-size: 20px;
}
.pzh_com_title {
  display: flex;
  padding: 80px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}


.pzh_menu_logo {
  width: 180px;
  margin-top: 22px;
  height: 31px;
  background: #182a85;
  border-radius: 5px;
  text-align: center;
}
.announcement {
  /* margin-top: 50px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.platform {
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background: #ffffff;
  width: 1200px;
  margin: 0 auto;
}

.platform_left {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.platform_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
}
.platform_right_more {
  margin-right: 8px;
  font-size: 20px !important;
  color: #9b9b9b !important;
  text-decoration: none !important;
}

.platform_left_title {
  color: #9b9b9b;
  font-size: 20px;
}
.arrow_icon {
  width: 25px;
  height: 25px;
}
.banner {
  background-image: url("../assets/luyi/bg1.png");
  width: 100%;
  height: 445px;
}
.banner_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  height: 100%;
}
.banner_left {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.banner_left_title1 {
  color: #fff;
  font-size: 48px;
}
.banner_left_title2 {
  color: #fff;
  font-size: 36px;
}
.banner_right {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 360px;
  background: #ffffff;
}
.pzh_menu_large_img {
  width: 1202px;
  height: 357px;
  position: relative;
}

.table-container {
  background-color: #fff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  padding: 0 40px;
  padding-bottom: 10px;
  width: 1120px;
  margin-bottom: 150px;
}
.tabs {
  font-weight: 500;
  border-bottom: 1px solid #eee;
  margin-bottom: 55px;
  display: flex;
  justify-content: center;
  gap: 124px;
}
.tab {
  font-size: 14px;
  padding: 20px 0;
  cursor: pointer;
  color: #3fae73;
  border-bottom: 4px solid #3fae73;
}

.input_cell {
  width: 240px;
  height: 36px !important;
}
</style>