<template>
<div class="distribution">
      <div class="max-1200">
        <div class="title">商会业务分布</div>
        <div class="eng">Service distribution</div>
        <div id="chinaMap" class="map"></div>
      </div>
    </div>
</template>
<script setup>

  import useChinaMap from "@/hooks/useChinaMap";
  /**
   ** 可视化地图
  */
  useChinaMap("chinaMap");

</script>
<script>
import aEntityAPI from "@/api/sysAPI.js";
export default {
    data() {
        return {
            imgIndexList:[],
            imgList:[
                require("@/assets/luyi/1.png"),
                require("@/assets/luyi/2.png"),
                require("@/assets/luyi/3.png")
            ],
            grid_pageNo: 1,
            grid_total: 0,
            grid_pageSize: 10,
            supplierData: [
            ],
        };
    },
    methods: {
        getImg(){
            let num= Math.floor(Math.random() * 3)
            return this.imgList[num]
        },
        pageChange() {
            this.findMyCarrierList4Luyi();
        },
        findMyCarrierList4Luyi() {
            // let arry =[]
            // for (var i = 0; i < 3 ; i++) {
            //     let num= Math.floor(Math.random() * 3)
            //     arry.push(num)
            // }
            // console.log(arry,'222');
            // this.imgIndexList = arry
            let params = {
                start: this.grid_pageSize * (this.grid_pageNo - 1),
                limit: this.grid_pageSize,
                q: {},
            };
            aEntityAPI
                .request("TeamCarrierService", "findMyCarrierList4Pzh", {
                    p: JSON.stringify(params),
                })
                .then((res) => {
                    if (res.data.success) {
                        this.supplierData = res.data.data;
                        this.grid_total = res.data.total
                    } else {
                        this.$message.error(res.data.msg);
                    }
                });
        },
    },
    mounted() {
        // this.findMyCarrierList4Luyi();
    },
};
</script>
<style>
.distribution {
  box-sizing: border-box;
  width: 100%;
  padding: 80px 60px 0;
  background: no-repeat center/cover url("../assets/luyi/distribution_back.png");
  
}
.max-1200 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
/* .title {
    font-size: 28px;
    font-weight: 600;
    color: #fff;
    text-align: center;
  }
.eng {
  margin-top: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-align: center;
} */
.title {
  color: #fff;
  margin: 0px 20px;
  font-size: 36px;
  font-weight: 500 !important;
  text-align: center;
}
.eng {
  color: #fff;
  margin-top: 5px;
  font-family: Source Han Sans SC;
  font-size: 20px;
  text-align: center;
}
.map {
  width: 1200px;
  height: 800px;
  position: relative;
  right: 100px;
  bottom: 80px;
}

.text-green{
  color: #3FAE73;
}
.text-gray{
  color: #9B9B9B;
}
</style>