<template>
    <div style="width:100%;height:100%;position: relative;">

        <el-form ref="loginFormRef" :model="LoginForm" class="login-form" :rules="rules">
            <div class="login-title">
                用户登录
            </div>

        
            <span>
                <el-form-item class="input-cell" prop="loginName">
                    <el-input ref="loginName" type="input" placeholder="请输入登录名或手机号" v-model="LoginForm.loginName"
                        autocomplete="off" maxlength="20" v-on:keyup.enter.native="login" @change="loginNameChange">
                      
                    </el-input>
                </el-form-item>
                <el-form-item prop="password" class="input-cell" style="margin-top: 30px;">
                    <el-input type="password" placeholder="请输入登录密码" v-model.trim="LoginForm.password" autocomplete="off"
                        maxlength="20" v-on:keyup.enter.native="login" show-password>
                       
                    </el-input>
                </el-form-item>
                <el-form-item v-if="showVerify" prop="verify" style="margin:0 auto;margin-top: 30px;margin:0 auto;">
                    <el-row >
                        <el-col :span="12" style="margin-left:30px;">
                            <el-input type="input" placeholder="图片验证码（区分大小写）" v-model="LoginForm.verify"
                                autocomplete="off"></el-input>
                        </el-col>
                        <el-col :span="8">
                            <el-image @click="changeImgCode" fit="fill" :src="verifyUrl"
                                style="width: 100%;height:44px;cursor: pointer;margin-left:10px;" />
                            
                        </el-col>
                    </el-row>
                </el-form-item>
               
                <div :style="showVerify ? 'margin-top:20px;' : 'margin-top:60px;'" class="submit-cell" type="primary" @click="login"
                    v-loading.fullscreen.lock="fullscreenLoading">立即登录
                </div>
                <br />


                <div style="display:flex;align-items:center;justify-content:space-between;">
                    <el-button style="color:#666666;margin-left: 30px;" type="text" v-if="!isZbrl" @click="registBtn">立即注册</el-button>
                    <el-button type="text" style="margin-right: 30px;float:right;color:#666666;" @click="forgotPass">忘记密码</el-button>
                </div>
               

            </span>

        </el-form>
        
    </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import ls from "@/utils/ls";
import userAPI from "@/api/userAPI.js";
import aEntityAPI from "@/api/sysAPI.js";
import aes from './aes.js';
import QRCode from 'qrcode';
import io from "../components/socket.io";

export default {
    components: {},
    props: ["mobile", "saas", "fromUrl"],
    data() {
        return {
            timer: null,
            qrExpire: false,
            typeIndex:1,
            qrLoginLock: false,
            qrLoginerror: false,
            loginWebSocket: null,
            isQrcode: false,
            qrcode: null,
            uuid: null,

            isZbrl: false,
            fullscreenLoading: false,
            hrsaas: false,

           

            LoginForm: {
                loginName: "",
                password: "",
                verify: ""
            },
            showVerify: false,
            verifyUrl: window.location.protocol + "//" + window.location.host + process.env.VUE_APP_BACKEND_URL_PROXY + "/verify?randid=" + Math.abs(Math.sin(new Date().getTime())),
            rules: {
                loginName: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "change"
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "change"
                    }
                ],
                verify: [
                    {
                        required: true,
                        message: "请输入图片验证码",
                        trigger: "change"
                    }
                ]
            },
            loginRole: '',
            loginRoleName: '',
            isSaaS: false,
        };
    },
    computed: {
        ...mapGetters({
            userinfo: "user/userinfo",
            sysConfig: "config/sysConfig",
            shipper: "user/shipper",
            __menu: "user/menu"
        })
    },

    created() {
        if (ls.get("LoginError") != null && ls.get("LoginError") == 1) {
            this.showVerify = true
        }
       
    },

    methods: {
        ...mapActions(["user/login", "user/logout", "user/checkLogin"]),



  
        loginNameChange(v) {

            /*检查是否需要图片验证码*/
            userAPI.needVerify(v).then(response => {
                //双要素验证
                if (response.status != null && response.status == 200) {
                    if (response.data.needVerify) {
                        this.showVerify = true
                    }
                }
            });

        },



        login() {
            this.$refs.loginFormRef.validate(valid => {
                if (valid) {
                    this.fullscreenLoading = true;
                    var param = { ...this.LoginForm }
                    
                    //加密密码防止泄露
                    param.passwordE = aes.encrypt(param.password)
                    param.password = null;
                    console.log(param);
                    if (this.hrsaas) {
                        param.platform = 7
                    }
                    if (this.isSaaS) {
                        param.platform = 2
                    }
                    console.log(param);
                    this["user/login"](param).then(data => {
                        this.fullscreenLoading = false;
                        if (data.success) {
                            console.log(data)
                            var tenantInfo = data.tenantInfo

                            if (tenantInfo?.domain) {
                                ls.set("tenantDomain", tenantInfo.domain.split(",")[0])
                            }

                            if (tenantInfo != null && tenantInfo.status != null
                                && (
                                    (typeof tenantInfo.status == 'object' && [-7, -5].indexOf(tenantInfo.status.value) > -1) ||
                                    (typeof tenantInfo.status == 'string' && ['已提交帐号', '已提交授权'].indexOf(tenantInfo.status) > -1)
                                ) && (this.isSaaS || this.hrsaas)) {
                                //saas注册用户的tenantInfo的状态还在待审核阶段，需转到到saas注册页面
                                // window.location.href = "login.html#/regSaas"
                                this.$router.push({
                                    name: 'regSaas'
                                });
                                return;
                            }
                            if (sessionStorage.getItem("beforeLoginUrl") == null) {
                                if (data.user.firstLogin) {
                                    //首次登录
                                    this.$router.push({ name: "resetPass" });
                                } else {
                                     let manageFlag = false;
                                            for (var i = 0; i < this.__menu.length; i++) {
                                                if (this.__menu[i].mxtype == 'manage') {
                                                    manageFlag = true;
                                                }
                                            }

                                            if (manageFlag) {
                                                console.log(this.__menu,'this.__menu11')
                                                
                                                window.location.href = "manage.html#/home";
                                            } else {
                                                console.log(this.__menu,'this.__menu22')
                                                
                                                
                                                window.location.href = "common.html#/home";
                                            }
                                    // if (this.fromUrl != null && this.fromUrl) {
                                    //     console.log(this.fromUrl,'this.fromUrl')
                                    //     window.location.href = decodeURIComponent(this.fromUrl);
                                    // } else {
                                    //     //判断默认进入的系统
                                    //     if (this.__menu.length == 1) {
                                    //     console.log(this.__menu,'this.fromUrl')
                                    //         //一个系统菜单，只进这个系统
                                    //         const url = this.__menu[0].url;
                                    //         window.location.href = url;
                                    //     } else if (this.__menu.length > 1) {
                                    //         //多个系统菜单
                                    //         let manageFlag = false;
                                    //         for (var i = 0; i < this.__menu.length; i++) {
                                    //             if (this.__menu[i].mxtype == 'manage') {
                                    //                 manageFlag = true;
                                    //             }
                                    //         }

                                    //         if (manageFlag) {
                                    //             console.log(this.__menu,'this.__menu11')
                                    //             window.location.href = "manage.html#/home";
                                    //             window.location.reload();
                                    //         } else {
                                    //             const url = this.__menu[0].url
                                    //             console.log(this.__menu,'this.__menu22')
                                    //             window.location.href = url
                                    //         }
                                    //     } else {
                                    //         //无菜单
                                    //         this.$alert("无系统权限，将返回登录", "登录失败", {
                                    //             confirmButtonText: "确定",
                                    //             callback: action => {
                                    //                 this["user/logout"]().then(() => {
                                    //                     window.location.reload();
                                    //                 });
                                    //             }
                                    //         });
                                    //     }
                                    // }
                                }

                            } else {
                                var beforeLoginUrl = sessionStorage.getItem("beforeLoginUrl")
                                sessionStorage.removeItem("beforeLoginUrl")
                                window.location.href = beforeLoginUrl;
                            }

                        } else {
                            let message = data.msg == null ? "" : data.msg;
                            if (message == "该号码未注册") {
                                // this.registBtn();
                                this.$message({
                                    message: "该手机号未注册，请您先注册！",
                                    type: "error"
                                });
                            } else {
                                if (data.errorCode == 407) {
                                    this.$message({
                                        message: "已启用超管的密码 + 短信验证码双要素登录策略",
                                        type: "info"
                                    });
                                    return;
                                }

                                if (data.errorTimes >= 3 || message == "图片验证码不正确") {
                                    this.showVerify = true;
                                    ls.set("LoginError", 1);
                                }
                                this.$message({
                                    message: message,
                                    type: "error"
                                });
                                if (this.showVerify) {
                                    //刷新验证码
                                    this.changeImgCode();
                                }
                            }
                        }
                    });
                }
            });
        },
    
        registBtn() {
         this.$set(this,"typeIndex",2);
         console.log(url)
        var url =
          window.location.protocol +
          "//" +
          window.location.host +
          "/luyi.html#/" +
          "luyi?typeIndex=" + 2;
        console.log(url,'urlurl')
        window.location.href = url;
        
        
        
        document.documentElement.scrollTop = 0;
        document.location.reload();

        },
        forgotPass() {
            var url =
          window.location.protocol +
          "//" +
          window.location.host +
          "/luyi.html#/" +
          "luyi?typeIndex=" + 3;
        window.location.href = url;
        document.documentElement.scrollTop = 0;
        document.location.reload();
        },
        changeImgCode() {

            this.LoginForm.verify = "" //清空验证码

            this.verifyUrl =
                window.location.protocol +
                "//" +
                window.location.host +
                process.env.VUE_APP_BACKEND_URL_PROXY +
                "/verify?randid=" +
                Math.abs(Math.sin(new Date().getTime()));
        }
    },

    mounted() {
        var href = window.location.href;
        if (this.mobile) {
            this.LoginForm.loginName = this.mobile;
        }

        if (href.indexOf("hrsaas") > -1 || href.indexOf("saas.gz-vip.com") > -1) {
            this.hrsaas = true;
            this.loginRole = '工众人力平台';
            this.loginRoleName = '工众人力平台用户';
        } else if (window.location.host.indexOf("saas.") > -1) {
            this.saas = true;
        }
        if (this.saas != null) {
            this.loginRole = 'SaaS';
            this.isSaaS = true;
            this.loginRoleName = 'SaaS用户';
        }

        if (href.indexOf("zbrl.gsh56.com") > -1
            || href.indexOf("testzbrl.gsh56test.com") > -1
            // || href.indexOf("zbrl02.gsh56test.com") > -1
            // || href.indexOf("hr.gzh-vip.com") > -1
            || href.indexOf("testzbrl.gsh56test.com") > -1
            || href.indexOf("devzbrl.gsh56.com") > -1) {
            this.isZbrl = true;
        }

        var that = this
        setTimeout(function () {
            that.$refs['loginName'].focus()
        }, 250)

    },
};
</script>
<style scoped>
.login-divider.el-divider--horizontal {
    margin: 12px 0;
}


::v-deep .el-input__inner:focus {
  border-color: #3FAE73 !important;
}
::v-deep .el-input__inner:active {
  border-color: #3FAE73 !important;
}

.login-form{
    width: 300px;
    height: 340px;
    background: #FFFFFF;
}
.login-title{
    padding: 18px 0;
    text-align: center;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #3FAE73;
}

.input-cell{
    width:240px;
    height: 36px;
    margin: 0 auto;
}


.wrapper {
    position: relative;
    display: inline-block;
}

.submit-cell{
    width: 240px;
    height: 36px;
    background: #3FAE73;
    color: #fff;
    border-radius: 2px; 
    margin: 0 auto;
    display: flex;
    align-items: center; 
    justify-content: center;
}


.mask {
    position: absolute;
    left: 24%;
    /* top: 0;
  left: 0; */
    width: 200px;
    height: 200px;
    background-color: #FFF;
    /* 蒙版层的颜色 */
    opacity: 0.9;
    /* 蒙版层的透明度, 可设置0-1之间的数值*/
}
</style>
