<template>
    <div class="share" style="height: 652px;">
        <div class="register_title" style="margin-bottom: 36px;padding-top: 40px;">供应商注册</div>
        <div class="certificate">
            <div class="upload" style="border-right: 1px solid #63626385;">
                <div>
                    <div class="upload_title">营业执照</div>
                    <div style="width: 368px; height: 50px;">
                        <img class="icons" src="@/assets/luyi/拷贝.png">
                        请提供真实的营业执照原件照片，文字盖章清晰可辨认，格式
                        要求jpg.pngjpeg,图片大小不超过5M
                    </div>
                    <!-- <img class="upload_img" src="@/assets/luyi/预览.png"> -->
                    <el-upload class="upload-uploader" accept="image/jpeg, image/png, image/jpg" :before-upload="handleBeforeUpload" :show-file-list="false"
                        :on-success="handleFileSuccess" :action="actionUrl" name="filedata" :data="params">
                        <img v-if="fileUrl" :src="fileUrl" class="upload_img" />
                        <img v-else class="upload_img" src="@/assets/luyi/预览.png">
                    </el-upload>
                    <div>
                        <img class="icons" src="@/assets/luyi/拷贝.png">
                        证件上传支持OCR文字识别，可自动填充内容，建议先上传图片
                    </div>
                    <el-input class="upload_input" v-model="currentShipper.name" placeholder="请输入企业名称"></el-input>
                    <el-input class="upload_input" v-model="currentShipper.no" placeholder="请输入营业执照注册号"></el-input>
                </div>
            </div>
            <div class="upload">
                <div class="upload_title">法人身份证</div>
                <div style="width: 368px;
                height: 50px;">
                    <img class="icons" src="@/assets/luyi/拷贝.png">
                    请提供真实的身份证原件正反面照片，文字清晰可辨认，格式
                    要求jpg.pngjpeg,图片大小不超过5M
                </div>
                <div style="display: flex;
                justify-content: center;">
                    <div style="padding-right: 68px">
                        <el-upload class="upload-uploader" accept="image/jpeg, image/png, image/jpg"  :before-upload="handleBeforeUpload" :show-file-list="false"
                            :on-success="handleIdcardFileSuccess" :action="actionUrl" name="filedata" :data="params">
                            <img v-if="idCard_fileUrl" :src="idCard_fileUrl" class="upload_img" />
                            <img v-else class="upload_img" src="@/assets/luyi/idcard_z.png">
                        </el-upload>
                    </div>
                    <div>
                        <el-upload class="upload-uploader" accept="image/jpeg, image/png, image/jpg" :before-upload="handleBeforeUpload" :show-file-list="false"
                            :on-success="handleIdcardBackFileSuccess" :action="actionUrl" name="filedata" :data="params">
                            <img v-if="idCardBack_fileUrl" :src="idCardBack_fileUrl" class="upload_img" />
                            <img v-else class="upload_img" src="@/assets/luyi/idcard_f.png">
                        </el-upload>
                    </div>
                </div>
                <div>
                    <img class="icons" src="@/assets/luyi/拷贝.png">
                    证件上传支持OCR文字识别，可自动填充内容，建议先上传图片
                </div>
                <el-input class="upload_input" v-model="currentShipper.idcard_name" placeholder="请输入姓名"></el-input>
                <el-input class="upload_input" v-model="currentShipper.idcard_no" placeholder="请输入身份证号"></el-input>
            </div>
        </div>
        <div>
            <el-button type="primary" style="width: 320px;height: 48px;font-size: 20px;margin-top: 16px;" @click="saveRegionDomain4Pzh" :loading="submitting">提交</el-button>
        </div>

    </div>
</template>

<script>
import aEntityAPI from '@/api/aEntityAPI.js';
import shipperAuthAPI from "@/project/wlhy/api/shipperAuthAPI.js";
export default {
    data() {
        return {
            submitting: false,
            params: {
                thumb: true,
                width: 326,
                height: 200,
            },
            currentShipper: {
                name: '',
                no: '',
                idcard_name: '',
                idcard_no: '',
            },
            fileUrl: "",
            fileGuid: "",
            idCard_fileUrl: "",
            idCardGuid:"",
            idCardBack_fileUrl: "",
            idCardBackGuid:"",
            actionUrl: process.env.VUE_APP_BACKEND_URL_PROXY + "/file/upload",
        };
    },
    methods: {
        handleIdcardFileSuccess(res, file) {
            this.idCard_fileUrl = this.__ftpPath + res.data.filePath;
            this.idCardGuid = res.data.guid;
            this.ocr(res.data.id, 3, true);
        },
        handleIdcardBackFileSuccess(res, file) {
            this.idCardBack_fileUrl = this.__ftpPath + res.data.filePath;
            this.idCardBackGuid = res.data.guid;
        },
        handleFileSuccess(res, file) {
            this.fileUrl = this.__ftpPath + res.data.filePath;
            this.fileGuid = res.data.guid;
            this.ocr(res.data.id, 5, false);
        },
        handleBeforeUpload(file) {
            const isLt5M = file.size / 1024 / 1024 < 20;
            if (!isLt5M) {
                this.$message.error("上传头像图片大小不能超过 20MB!");
                return false;
            }
            return true;
        },
        ocr(fileId, type, autoRotate) {
            aEntityAPI.request('OcrService', 'licOcr', {fileId: fileId, type: type,autoRotate: autoRotate}).then(response => {
                if (response.data.success) {
                    if (type == 3 && response.data.data.num) {
                        this.currentShipper.idcard_no = response.data.data.num;
                    }
                    if (type == 3 && response.data.data.name) {
                        this.currentShipper.idcard_name = response.data.data.name;
                    }
                    if (type == 5 && response.data.data.reg_num) {
                        this.currentShipper.no = response.data.data.reg_num;
                    }
                    if (type == 5 && response.data.data.name) {
                        this.currentShipper.name = response.data.data.name;
                    }
                }
            })
        },

        saveRegionDomain4Pzh(){
            if( !this.fileUrl){
                this.$message.info("请上传营业执照")
                return;
            }
            if( !this.idCard_fileUrl){
                this.$message.info("请上传身份证正面")
                return;
            }
            if( !this.idCardBack_fileUrl){
                this.$message.info("请上传身份证反面")
                return;
            }
            if( !this.currentShipper.name){
                this.$message.info("企业名称不能为空！")
                return;
            }
            if( !this.currentShipper.no){
                this.$message.info("企业营业执照号不能为空！")
                return;
            }
            if( !this.currentShipper.idcard_name){
                this.$message.info("姓名不能为空！")
                return;
            }
            if( !this.currentShipper.idcard_no){
                this.$message.info("身份证号不能为空！")
                return;
            }
            this.submitting = true;;
            shipperAuthAPI.saveRegionDomain4Pzh(this.currentShipper, this.fileGuid, this.idCardGuid, this.idCardBackGuid).then(res => {
                this.submitting = false;
                if (res.data.success) {
                    this.$message.success('提交成功');
                    window.location.href = "luyi.html#/biddingHall";
                } else {
                    this.$message({
                        showClose: true,
                        message: res.data.msg ? res.data.msg : "提交失败",
                        type: "warning",
                    });
                }
            });
        }
    },
};
</script>
<style>
.company-avatar {
    width: 120px;
    height: 65px;
    display: block;
    object-fit: contain;
}

.icons {
    width: 13px;
}
.upload_input {
    width: 368px !important;
    height: 44px;
    margin: 8px 0;
}
.upload {
    width: 428px;
    text-align: center;
}
.upload_img {
    max-height: 125px;
    margin: 40px 0 36px;
    max-height: 80px;
}
.upload_title {
    margin-bottom: 40px;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #333333;
}
.certificate {
    display: flex;
}
</style>