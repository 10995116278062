
import CryptoJS from 'crypto-js';
 
export default {
    //随机生成指定数量的16进制key
    generatekey(num) {
        let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let key = "";
        for (var i = 0; i < num; i++) {
            let randomPoz = Math.floor(Math.random() * library.length);
            key += library.substring(randomPoz, randomPoz + 1);
        }
        return key;
    },

    encrypt(word){
        /**/
        // var key = CryptoJS.enc.Utf8.parse("0102030405060708"); //加密密钥
        // // var iv = CryptoJS.enc.Utf8.parse('0102030405060708'); //加密向量
        // var srcs = CryptoJS.enc.Utf8.parse(word);
        // var encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: key,mode:CryptoJS.mode.CBC});
        // return encrypted.toString();

        var key = CryptoJS.enc.Utf8.parse("gsh56comtest%^$#") //16位
        var iv = CryptoJS.enc.Utf8.parse("gsh56comtest%^$#")
        var encrypted = ''

        var srcs = CryptoJS.enc.Utf8.parse(word);
        encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        })
    
        return encrypted.ciphertext.toString()
    },
    
    /*加密
    encrypt(src) {
        // var key = '1234567890ABCDEF1234567890ABCDEf';
        // console.log('密钥：', key);
        // key = CryptoJS.enc.Hex.parse(key)
        // var iv = CryptoJS.enc.Hex.parse("30313233343536373839414243444546")
        // var src = word;
        // console.log('原字符串：', src);
        // var enc = CryptoJS.AES.encrypt(src ,key,{
        //     iv:iv,
        //     mode: CryptoJS.mode.CBC,  
        //     padding: CryptoJS.pad.Pkcs7
        // })

        // //console.log('加密:',enc.toString());
        // var enced = enc.ciphertext.toString()
        // console.log("加密：", enced);
        // return enced
        
        var key = '3132333435363738393041424344454631323334353637383930414243444566';
        // 1234567890ABCDEF1234567890ABCDEf
        console.log('密钥：', key);
        key = CryptoJS.enc.Hex.parse(key)
        //iv = CryptoJS.enc.Hex.parse("30313233343536373839414243444546")
        //console.log('偏移量:',CryptoJS.enc.Hex.stringify(iv));
        console.log('原字符串：', src);
        var enc = CryptoJS.AES.encrypt(src ,key,{
            //iv:iv,
            mode: CryptoJS.mode.ECB,  
            padding: CryptoJS.pad.Pkcs7
        })
        var enced = enc.ciphertext.toString()
        console.log("加密：", enced);
        return enced
    },*/

    downloadFile(guid) {
        if (guid) {
            console.log(window.location.protocol + '//' + window.location.host + process.env.VUE_APP_BACKEND_URL_PROXY + "/file/get?guid=" + guid,'fff')
            window.open(window.location.protocol + '//' + window.location.host + process.env.VUE_APP_BACKEND_URL_PROXY + "/file/get?guid=" + guid);
        }
        
    },

    //解密
    decrypt(word, keyStr) {
        keyStr = keyStr ? keyStr : 'just4security';
        var key = CryptoJS.enc.Utf8.parse(keyStr);
        var decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    }
 
}